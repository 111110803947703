import {apiAxios as authAxios} from "../axios.config";

export const getSemestralPaymentRequestByReport = (externalId) => {
    return authAxios.get(`/api/semestralReport/${externalId}/paymentRequest`);
};

export const getSemestralPaymentListRequestByReport = (externalId) => {
    return authAxios.get(`/api/semestralReport/${externalId}/paymentRequests`);
};

export const saveSemestralPaymentRequestByReport = (externalId, data) => {
    return authAxios.patch(`/api/semestralReport/${externalId}/paymentRequest`, data);
};

export const submitSemestralPaymentRequestByReport = (externalId, data) => {
    return authAxios.post(`/api/semestralReport/${externalId}/paymentRequest/submit`, data);
};

export const decideSemestralPaymentRequestByReport = (externalId, data) => {
    return authAxios.post(`/api/semestralReport/${externalId}/paymentRequest/decide`, data);
};

export const listSemestralPaymentRequestDocuments = (externalId) => {
    return authAxios.get(`/api/semestralReport/${externalId}/paymentRequest/document`)
}

export const uploadSemestralPaymentRequestDocument = (externalId, formData) => {
    return authAxios.post(`/api/semestralReport/${externalId}/paymentRequest/document`, formData)
}

export const deleteSemestralPaymentRequestDocument = (externalId, documentExternalId) => {
    return authAxios.delete(`/api/semestralReport/${externalId}/paymentRequest/document/${documentExternalId}`)
}

export const getPaymentRequestByProcessExternalId = (externalId) => {
    return authAxios.get(`/api/paymentRequest/process/${externalId}`);
};

export const listDocumentsByProcessExternalId = (externalId) => {
    return authAxios.get(`/api/paymentRequest/process/${externalId}/document`);
};

export const getFinalPaymentRequestByReport = (externalId) => {
    return authAxios.get(`/api/finalReport/${externalId}/paymentRequest`);
};

export const getFinalPaymentListRequestByReport = (externalId) => {
    return authAxios.get(`/api/finalReport/${externalId}/paymentRequests`);
};

export const saveFinalPaymentRequestByReport = (externalId, data) => {
    return authAxios.patch(`/api/finalReport/${externalId}/paymentRequest`, data);
};

export const submitFinalPaymentRequestByReport = (externalId, data) => {
    return authAxios.post(`/api/finalReport/${externalId}/paymentRequest/submit`, data);
};

export const decideFinalPaymentRequestByReport = (externalId, data) => {
    return authAxios.post(`/api/finalReport/${externalId}/paymentRequest/decide`, data);
};

export const listFinalPaymentRequestDocuments = (externalId) => {
    return authAxios.get(`/api/finalReport/${externalId}/paymentRequest/document`)
};

export const uploadFinalPaymentRequestDocument = (externalId, formData) => {
    return authAxios.post(`/api/finalReport/${externalId}/paymentRequest/document`, formData)
};

export const deleteFinalPaymentRequestDocument = (externalId, documentExternalId) => {
    return authAxios.delete(`/api/finalReport/${externalId}/paymentRequest/document/${documentExternalId}`)
};
