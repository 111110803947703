import React, { useEffect, useRef, useState } from 'react';
import {Button, Col, Container, Form, Modal, Nav, Row, Toast, ToastContainer} from 'react-bootstrap';
import { FaExclamationCircle, FaRegCheckCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import {Link, Navigate, useParams} from 'react-router-dom';
import { AlertError } from '../../components/bootstrap/AlertError';
import { ContestationPartialApprovalForm } from '../../components/candidature/ContestationPartialApprovalForm';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { CandidatureState } from '../../models/CandidatureState';
import { DocumentTypeDescriptor } from '../../models/DocumentTypeDescriptor';
import { getCandidatureByExternalId, submitPartialApproval } from '../../rest/candidature';
import { listCandidatureDocumentsByDescriptions } from '../../rest/document';
import { Arrays } from '../../utils/Arrays';
import { CandidatureEmployeeSupportUtils, IAS } from '../../utils/CandidatureEmployeeSupportUtils';
import { CandidatureFormCustomValidator } from '../../utils/CandidatureFormCustomValidator';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {createCustomErrorMessage} from "../../hooks/errorMessage";
import {PromoterType} from "../../models/PromoterType";
import {TimeType} from "../../models/TimeType";
import {maxFixed} from "../../utils/CurrencyUtils";
import {FaCheck} from "react-icons/fa";
import {DocumentType} from "../../models/DocumentType";
import {createMultiPart, SyncMultipleUploadArea} from "../../components/SyncMultipleUploadArea";
import {SyncSingleUploadArea} from "../../components/SyncSingleUploadArea";
import {Formik} from "formik";
import {TextInputField} from "../../components/bootstrap/TextInputField";
import {TextAreaInputField} from "../../components/bootstrap/TextAreaInputField";

export function CandidatureContestationAnalysisPartialApproval({isContestation,isYearChange }) {
  const { externalId } = useParams();

  const intl = useIntl();

  const [show, setShow] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTab, setCurrentTab] = useState(1);
  const [candidature, setCandidature] = useState(null);
  const [navErrors, setNavErrors] = useState([]);
  const [candidatureDocuments, setCandidatureDocuments] = useState([]);
  const [publicDocument, setPublicDocument] = useState(null);
  const [privateDocument, setPrivateDocument] = useState(null);
  const [publicDocumentType, setPublicDocumentType] = useState(DocumentType.VALIDATION_DOCUMENT);
  const [privateDocumentType, setPrivateDocumentType] = useState(DocumentType.VALIDATION_DOCUMENT_PRIVATE);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [titleId, setTitleId] = useState('');
  const [showSaveToast, setShowSaveToast] = useState(false);
  const [globalTotalInitial, setGlobalTotalInitial ] = useState(null);

  const formikPropsRef = useRef();

    async function handleShow() {
        let errors = await validateAllForm(false);
        setNavErrors(errors);

        if (Arrays.isEmpty(errors)) {
            setShow(true);
        }
    }
  const handleClose = () => setShow(false);

  function canSubmit() {
    return publicDocument !== null && privateDocument !== null;
  }

  useEffect(() => {
    fetchData();
  }, []);


  function getSaveToastInformation(message) {
    return (
        <ToastContainer className='fixed-top'>
          <Toast autohide delay={3000} show={showSaveToast} onClose={() => setShowSaveToast(false)}>
            <Toast.Header>
              <strong className='me-auto text-primary'>
              <span className='mr-2'>
                <FaCheck />
              </span>
                {message}
              </strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
    );
  }



  async function fetchData() {
    try {
      let documentTypes;
      let titleId;

      const { data: candidature } = await getCandidatureByExternalId(externalId);

      documentTypes =
          (candidature.state === CandidatureState.CONTESTATION_ANALYSIS || isYearChange===true)
          ? ['FINAL_DECISION', 'FINAL_DECISION_PRIVATE']
          : ['VALIDATION_DOCUMENT', 'VALIDATION_DOCUMENT_PRIVATE'];

      titleId =
        candidature.state === CandidatureState.CONTESTATION_ANALYSIS
          ? 'candidature.title.contestationAnalysisPartialApproval'
          :  (isYearChange===true ? 'candidatureValidation.title.approve' :'candidature.title.validationPartialApproval');

      const { data: candidatureDocuments } = await listCandidatureDocumentsByDescriptions(
        DocumentTypeDescriptor.CANDIDATURE,
        externalId,
        documentTypes
      );

      setPublicDocumentType(documentTypes[0]);
      setPrivateDocumentType(documentTypes[1]);


      candidature.investmentPlanDetails = getCorrectInvestmentPlanDetails(candidature);

      candidature.economicViabilityAccretionValidation = getCorrectEconomicViabilityAccretionValidation(candidature);

      const initalFinancialInvestmentDetails = candidature.financialInvestmentDetails.filter(
          (fid) => fid.kind === 'PROMOTER'
      );

      candidature.financialInvestmentDetails = getCorrectFinancialInvestmentDetails(candidature);









      candidature.candidatureEmployeeSupportDetails = getCandidatureEmployeeSupportDetailsValues(
        candidature.candidatureEmployeeSupportDetails,candidature.promotersMetaData, candidature.ias
      );


      if(isYearChange===true){
        candidature.candidatureEmployeeSupportDetails.forEach((detail) => {
          detail.totalSubsidy = (detail.timeType === 'TOTAL')
              ? 15 * candidature.ias
              : (parseFloat(15 * candidature.ias) * parseInt(detail.hours)) / 40;
        });

      }
      setCandidature(candidature);


      setGlobalTotalInitial(initalFinancialInvestmentDetails.find(
          (fid) => fid.financialInvestmentType === 'TOTAL'
      ).globalTotal);

      setCandidatureDocuments(candidatureDocuments);
      setTitleId(titleId);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function getCorrectInvestmentPlanDetails(candidature) {
    let investmentPlanDetails = candidature.investmentPlanDetails.filter(
      (ipd) => ipd.kind === 'TEMPORARY_CANDIDATURE'
    );

    if (Arrays.isNotEmpty(investmentPlanDetails)) {
      return investmentPlanDetails;
    }



    investmentPlanDetails = candidature.investmentPlanDetails.filter(
        (ipd) => ipd.kind === 'FINAL'
    );

    if (Arrays.isNotEmpty(investmentPlanDetails)) {
      return investmentPlanDetails;
    }


    investmentPlanDetails = candidature.investmentPlanDetails.filter((ipd) => ipd.kind === 'IEFP');
    console.log(candidature.investmentPlanDetails)
    if (Arrays.isNotEmpty(investmentPlanDetails)) {
      return investmentPlanDetails;
    }

    investmentPlanDetails = candidature.investmentPlanDetails.filter(
      (ipd) => ipd.kind === 'PROMOTER'
    );

    return investmentPlanDetails;
  }



  function getCorrectEconomicViabilityAccretionValidation(candidature) {
   let temporary = candidature.economicViabilityAccretionValidations.find(detail=>detail.kind==='TEMPORARY_CANDIDATURE');

   if(Arrays.isNotEmpty(temporary)){
     return  temporary;
   }

    if(!isContestation){
      return {isSubRepresentationValid:null,
        isInnovationProjectValid:null,
        reasonToSubRepresentation:'',
        reasonToInnovationProject:'',
        isLevelsValid:null,
        reasonToLevels:'',
        levelsValue:0,
        isInsideValid:null,
        reasonToInside:'',
        isIefpWorkstationsValid:null,
        reasonToIefpWorkstations:'',
        iefpWorkstationsValue:0


      }
    }
      return candidature.economicViabilityAccretionValidations.find(detail=>detail.kind==='IEFP')

  }



  function getCorrectFinancialInvestmentDetails(candidature) {




    let financialInvestmentDetails = candidature.financialInvestmentDetails.filter(
        (fid) => fid.kind === 'TEMPORARY_CANDIDATURE'
    );

    if (Arrays.isNotEmpty(financialInvestmentDetails)) {
      return financialInvestmentDetails;
    }


    financialInvestmentDetails = candidature.financialInvestmentDetails.filter(
      (fid) => fid.kind === 'FINAL'
    );

    if (Arrays.isNotEmpty(financialInvestmentDetails)) {
      return financialInvestmentDetails;
    }

    financialInvestmentDetails = candidature.financialInvestmentDetails.filter(
      (fid) => fid.kind === 'IEFP'
    );

    if (Arrays.isNotEmpty(financialInvestmentDetails)) {
      return financialInvestmentDetails;
    }

    financialInvestmentDetails = candidature.financialInvestmentDetails.filter(
      (fid) => fid.kind === 'PROMOTER'
    );

    return financialInvestmentDetails;
  }


  function getErrorInformation(value) {
    if (navErrors.includes(value)) {
      return (
        <span>
          <FaExclamationCircle />
        </span>
      );
    }
  }

  function handleNavClick(value) {
    setNavErrors(navErrors.filter((navError) => navError !== value));
    setCurrentTab(value);
    setCandidature({ ...candidature });
  }

  function getEmployeeDtoDetails(employeeDetails) {
    let employeeDetailsData = [];

    employeeDetails.forEach((detail) => {
      employeeDetailsData.push({
        externalId: detail.externalId,
        promoterMetaData: detail.promoterMetaData,
        hours:detail.hours,
        timeType:detail.timeType==='-1'? null : detail.timeType,
        totalSubsidy: detail.totalSubsidy,
        kind: detail.kind
      });
    });

    return employeeDetailsData;
  }






  function getCandidatureEmployeeSupportDetailsValues(employeeDetails,promotersMetaData, iasValue) {
    let values = [];
    let promotersFiltered = promotersMetaData.filter(p=> p.promoterType===PromoterType.PRIMARY || p.promoterType===PromoterType.SECONDARY)

    console.log('employeeDetails',employeeDetails);
    employeeDetails.forEach((detail) => {
      let valueToPush = {};


      valueToPush = {
        externalId: detail.externalId,
        promoterMetaData: detail.promoterMetaData? promotersFiltered.findIndex(
            (p) => p.externalId === detail.promoterMetaData
        )  :'-1',
        hours:detail.hours,
        timeType: detail.timeType? detail.timeType : '-1',
        kind: detail.kind
      };
      let hours = detail.hours
      if(hours===''){
        hours=0;
      }
      let totalSubsidyValue = maxFixed( (detail.promoterMetaData ==='-1' || detail.timeType==='-1' ) ? 0 : (detail.timeType===TimeType.PARTIAL? ( parseFloat(15 * iasValue)*parseInt(hours)/8) : 15*iasValue)    );


      valueToPush.totalSubsidy = parseFloat(totalSubsidyValue);
      values.push(valueToPush);
    });


    return values;
  }

    async function handleSubmit() {
        let errors = await validateAllForm(true);

        setNavErrors(errors);
        if (Arrays.isEmpty(errors)) {
            try {
                const documents = [];
                if (publicDocument) documents.push(publicDocument);
                if (privateDocument) documents.push(privateDocument);
                let dataToSend = getDataToSend();
                dataToSend.save = false;
                dataToSend.changeYear= (isYearChange===true);

                let formData = new FormData();
                if (Arrays.isNotEmpty(documents)) formData = createMultiPart(documents);
                formData.append('CandidatureDto', JSON.stringify(dataToSend));

                await submitPartialApproval(formData);
                setSubmited(true);
            } catch (error) {
                setError(error);
            }

        }
    }


  async function handleSave() {
    const documents = [];
    if(publicDocument) documents.push(publicDocument);
    if(privateDocument) documents.push(privateDocument);

    try {
      let dataToSend = getDataToSend();
      dataToSend.save = true;

      let formData = new FormData();
      if(Arrays.isNotEmpty(documents)) formData = createMultiPart(documents);
      formData.append('CandidatureDto', JSON.stringify(dataToSend));

      await submitPartialApproval(formData);
      setShowSaveToast(true);
    } catch (error) {
      setError(error);
    }
  }

  async function validateAllForm(validateDocuments) {
    let errors = [];
    let errorMessages = [];

    const formikErrors = await formikPropsRef.current.validateForm();
    console.log(formikErrors)
    Object.keys(formikErrors).forEach((key) => {

      switch (key) {
        case 'candidatureEmployeeSupportDetails':
          errors.push(3);
          break;
        case 'economicViabilityAccretionValidation':
          errors.push(4);
          break;
        default:
          break;
      }
    });

    if (
      !CandidatureFormCustomValidator.validateStep3(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      if (!errors.includes(1)) {
        errors.push(1);
      }
    }
    if( !CandidatureFormCustomValidator.validateStep6(formikPropsRef.current.values, setError, intl,errorMessages)){
      if (!errors.includes(1)) {
        errors.push(1);
      }
    }

    if(isYearChange===true){
      const totalFinancialInvestmentDetail = formikPropsRef.current.values.financialInvestmentDetails.find(
          (fid) => fid.financialInvestmentType === 'TOTAL'
      );
      if(globalTotalInitial>totalFinancialInvestmentDetail.globalTotal){
        errorMessages.push(intl.formatMessage({
          id: 'errors.candidatureForm.notDifferentFromOriginal'
        }));
        errors.push(2);
      }
    }

      if (validateDocuments && !canSubmit())
      {
        errorMessages.push(intl.formatMessage({
              id: 'need.documents'
          }));
          errors.push(6)
      }


    if(errorMessages.length>0){
      let message = (errorMessages.length === 1) ? createCustomErrorMessage(errorMessages[0]) : createCustomErrorMessage( '<p>'+  intl.formatMessage({
        id: 'errors.more'
      })+'</p><p> - '+ errorMessages.join('</p><p> - ')+'</p>') ;

      setError(message);
    }


    return errors;
  }

  function getDataToSend() {
    let valuesToSend = { ...formikPropsRef.current.values };

    //valuesToSend.candidatureFinancialDetails = [valuesToSend.candidatureFinancialDetails];

    valuesToSend.candidatureFinancialDetails = [];

    valuesToSend.candidatureEmployeeSupportDetails = getEmployeeDtoDetails(
      valuesToSend.candidatureEmployeeSupportDetails
    );

    valuesToSend.economicViabilityAccretionValidations = [valuesToSend.economicViabilityAccretionValidation]





    return valuesToSend;
  }

  if (submited) {
    return <Navigate replace to={`/candidatura/${externalId}`} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }

  return (
    <SubTemplate
      titleId={titleId}
      subTitleId={'candidature.subtitle.contestationAnalysisPartialApproval'}
      hasBackButton
      centerContent>
      <Container>
        <Modal  show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id={`${titleId}`} />
            </Modal.Title>
          </Modal.Header>
                  <Modal.Body>
                    <Row className='mt-4'>
                      <Col>
                        <small className='text-primary text-uppercase'>
                          <FormattedMessage id='candidatureValidation.decisionDocuments' />
                        </small>
                        <SyncSingleUploadArea
                            name='files'
                            document={publicDocument}
                            setDocument={setPublicDocument}
                            acceptExtensions='.pdf,.docx'
                            documentType={publicDocumentType}
                        />
                        <SyncSingleUploadArea
                            name='files'
                            document={privateDocument}
                            setDocument={setPrivateDocument}
                            acceptExtensions='.pdf,.docx'
                            documentType={privateDocumentType}
                        />
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer className='d-flex justify-content-between'>
                    <button
                        className={'btn btn-outline-primary px-5'}
                        onClick={handleClose}
                        type='button'
                    >
                      <FormattedMessage id='all.cancel' />
                    </button>
                    <button className='btn btn-primary px-5' onClick={handleSubmit} disabled={!canSubmit()}>
                      <FormattedMessage id='all.submitButtonText' />
                    </button>
                  </Modal.Footer>
        </Modal>
        <Row className='mb-5'>
          <Col md='2'>
            {/* <Nav
              variant='pills'
              defaultActiveKey={1}
              className='flex-column'
              activeKey={currentTab}>
              <Nav.Link
                className={`${navErrors.includes(1) ? 'activeError' : ''}`}
                eventKey={1}
                onClick={() => handleNavClick(1)}>
                <FormattedMessage id='candidatureTabs.investment' />
                {getErrorInformation(1)}
              </Nav.Link>
              <Nav.Link
                className={`${navErrors.includes(2) ? 'activeError' : ''}`}
                eventKey={2}
                onClick={() => handleNavClick(2)}>
                <FormattedMessage id='candidatureTabs.workStations' />
                {getErrorInformation(2)}
              </Nav.Link>
              <Nav.Link eventKey={3} onClick={() => handleNavClick(3)}>
                <FormattedMessage id='candidatureTabs.attachDecision' />
                {getErrorInformation(3)}
              </Nav.Link>
            </Nav> */}
            <Nav
              variant='pills'
              defaultActiveKey={1}
              className='flex-column'
              activeKey={currentTab}>
              <Nav.Link
                className={`${navErrors.includes(1) ? 'activeError' : ''}`}
                eventKey={1}
                onClick={() => handleNavClick(1)}>
                <FormattedMessage id='economicViabilityForm.investmentPlanTab' />
                {getErrorInformation(1)}
              </Nav.Link>
              <Nav.Link
                className={`${navErrors.includes(2) ? 'activeError' : ''}`}
                eventKey={2}
                onClick={() => handleNavClick(2)}>
                <FormattedMessage id='economicViabilityForm.financialInvestmentTab' />
                {getErrorInformation(2)}
              </Nav.Link>
              <Nav.Link
                className={`${navErrors.includes(3) ? 'activeError' : ''}`}
                eventKey={3}
                onClick={() => handleNavClick(3)}>
                <FormattedMessage id='candidatureTabs.workStations' />
                {getErrorInformation(3)}
              </Nav.Link>
              <Nav.Link eventKey={4} onClick={() => handleNavClick(4)}>
                <FormattedMessage id='economicViabilityForm.accretionTab' />
                {getErrorInformation(4)}
              </Nav.Link>
              <Nav.Link eventKey={5} onClick={() => handleNavClick(5)}>
                <FormattedMessage id='candidatureForm.accretion.resume' />
                {getErrorInformation(5)}
              </Nav.Link>
              {/*<Nav.Link eventKey={6} onClick={() => handleNavClick(6)}>*/}
              {/*  <FormattedMessage id='candidatureTabs.attachDecision' />*/}
              {/*  {getErrorInformation(6)}*/}
              {/*</Nav.Link>*/}
            </Nav>
          </Col>
          <Col md='8'>
            <AlertError error={error} />
            {getSaveToastInformation(
                intl.formatMessage({ id: 'validationForm.saveToastInformation' })
            )}
            <ContestationPartialApprovalForm
              candidature={candidature}
              documentTypes={documentTypes}
              publicDocument={publicDocument}
              setPublicDocument={setPublicDocument}
              privateDocument={privateDocument}
              setPrivateDocument={setPrivateDocument}
              candidatureDocuments={candidatureDocuments}
              setCandidatureDocuments={setCandidatureDocuments}
              formikPropsRef={formikPropsRef}
              error={error}
              setError={setError}
              numberOfSteps={6}
              currentStep={currentTab}
              isContestation={isContestation}
              isYearChange={isYearChange}

            />
          </Col>
          <Col md='2'>
            <button
                className='btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-3'
                type='button'
                onClick={() => handleSave()}>
              <FaRegCheckCircle />
              <FormattedMessage id='all.save' />
            </button>
            <button
              className='btn btn-primary d-flex align-items-center justify-content-center w-100'
              type='button'
              onClick={() => handleShow()}>
              <FaRegCheckCircle />
              <FormattedMessage id='all.submitButtonText' />
            </button>




          </Col>
        </Row>
      </Container>
    </SubTemplate>
  );
}
