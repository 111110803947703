import React, { useEffect, useRef, useState } from 'react';
import {Col, Container, Nav, Row, Toast, ToastContainer} from 'react-bootstrap';
import { FaExclamationCircle, FaRegCheckCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';
import { DocumentType } from '../../../models/DocumentType';
import {
	getCandidatureChangeRequestWithOrWithoutExternalId,
	listCandidatureChangeRequestDocumentsByType,
	submitApproveDecisionWithFinancialChanges,
} from '../../../rest/candidatureChangeRequest';
import { CandidatureChangeRequestApprovalForm } from './CandidatureChangeRequestApprovalForm';
import Loading from '../../../components/general/Loading';
import { SubTemplate } from '../../../components/general/SubTemplate';
import { createCustomErrorMessage } from '../../../hooks/errorMessage';
import { AlertError } from '../../../components/bootstrap/AlertError';
import { handleError, isNotBusinessError } from '../../../utils/handleError';
import {
	getCandidatureByExternalId,
	submitPartialApproval,
} from '../../../rest/candidature';
import { CandidatureFormCustomValidator } from '../../../utils/CandidatureFormCustomValidator';
import { Arrays } from '../../../utils/Arrays';
import {PromoterType} from "../../../models/PromoterType";
import {IAS} from "../../../utils/CandidatureEmployeeSupportUtils";
import {maxFixed} from "../../../utils/CurrencyUtils";
import {FaCheck} from "react-icons/fa";

export function CandidatureChangeRequestApproval() {
	const { candidatureExternalId, externalId } = useParams();

	const intl = useIntl();

	const [submited, setSubmited] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [currentTab, setCurrentTab] = useState(1);
	const [candidatureChange, setCandidatureChange] = useState(null);
	const [navErrors, setNavErrors] = useState([]);
	const [showSaveToast, setShowSaveToast] = useState(false);
	const [candidatureChangeDocuments, setCandidatureChangeDocuments] = useState(
		[]
	);
	const documentTypes = [
		DocumentType.FINAL_DECISION,
		DocumentType.FINAL_DECISION_PRIVATE,
		DocumentType.CANDIDATURE_CHANGE_TERM_ACCEPT,
	];
	const [newAcceptanceTerm, setNewAcceptanceTerm] = useState(false);

	const formikPropsRef = useRef();

	useEffect(() => {
		fetchData();
	}, []);

	async function fetchData() {
		try {
			let [
				{ data: candidature },
				{ data: finalDecisionDocument },
				{ data: finalDecisionDocumentPrivate },
				{ data: acceptanceTermDocument },
				{data: candidatureChangeIn}
			] = await Promise.all([
				await getCandidatureByExternalId(candidatureExternalId),
				await listCandidatureChangeRequestDocumentsByType(
					documentTypes[0],
					candidatureExternalId,
					externalId
				),
				await listCandidatureChangeRequestDocumentsByType(
					documentTypes[1],
					candidatureExternalId,
					externalId
				),
				await listCandidatureChangeRequestDocumentsByType(
					documentTypes[2],
					candidatureExternalId,
					externalId
				),
				await getCandidatureChangeRequestWithOrWithoutExternalId(candidatureExternalId,externalId)
			]);

			setCandidatureChange(getInitialValues(candidature,candidatureChangeIn));
			setCandidatureChangeDocuments([
				loadDocument(finalDecisionDocument, documentTypes[0]),
				loadDocument(finalDecisionDocumentPrivate, documentTypes[1]),
				loadDocument(acceptanceTermDocument, documentTypes[2]),
			]);

			setNewAcceptanceTerm(Arrays.isNotEmpty(acceptanceTermDocument));

		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}

	function loadDocument(data, documentType) {
		if (data.length !== 0) {
			const document = data[0];
			return {
				documentType: document.documentType,
				externalId: document.externalId,
				name: document.name,
				documentPath: document.documentPath,
				content: '',
				submited: true,
				error: '',
			};
		}

		return {
			documentType: documentType,
			externalId: '',
			name: '',
			content: null,
			submited: false,
			error: '',
		};
	}

	function getInitialValues(candidature,candidatureChangeIn) {
		let candidatureChangeRequest = {};
		candidatureChangeRequest.ias = candidature.ias;
		candidatureChangeRequest.candidatureDetails =
			candidature.candidatureDetails;

		candidatureChangeRequest.promotersMetaData = candidature.promotersMetaData;

		candidatureChangeRequest.candidatureFinancialDetails =
			candidature.candidatureFinancialDetails;

		let investmentPlanAux = candidatureChangeIn.investmentPlanDetails.filter(i=>i.kind === 'TEMPORARY_CHANGE');
		if(Arrays.isEmpty(investmentPlanAux)){
			investmentPlanAux =candidature.investmentPlanDetails.filter(i=>i.kind === 'FINAL');
		}
		investmentPlanAux.forEach(iP=> iP.kind='CANDIDATURE_CHANGE');
		candidatureChangeRequest.investmentPlanDetails =  investmentPlanAux;


		candidatureChangeRequest.economicViabilityAccretionValidation = (candidatureChangeIn.economicViabilityAccretionValidation!==undefined && candidatureChangeIn.economicViabilityAccretionValidation.kind==='TEMPORARY_CHANGE') ? candidatureChangeIn.economicViabilityAccretionValidation :
			getInitialEconomicViabilityAccretionValidation();

		console.log(candidatureChangeRequest);


		let financialInvestmentAux = candidatureChangeIn.financialInvestmentDetails?.filter(i=>i.kind === 'TEMPORARY_CHANGE');
		if(Arrays.isEmpty(financialInvestmentAux)){
			financialInvestmentAux =candidature.financialInvestmentDetails.filter(i=>i.kind === 'FINAL');
		}
		financialInvestmentAux.forEach(iP=> iP.kind='CANDIDATURE_CHANGE');
		candidatureChangeRequest.financialInvestmentDetails = financialInvestmentAux;
			//getInitialFinancialInvestmentDetails();



		let  eSupportAux = candidatureChangeIn.employeeSupportDetails?.filter(i=>i.kind === 'TEMPORARY_CHANGE');
		if(Arrays.isEmpty(eSupportAux)){
			eSupportAux = candidature.candidatureEmployeeSupportDetails.filter(i=>i.kind === 'FINAL');
		}

		eSupportAux.forEach(iP=> {
			iP.kind='CANDIDATURE_CHANGE'

			let promotersFiltered = candidature.promotersMetaData.filter(p=> p.promoterType===PromoterType.PRIMARY || p.promoterType===PromoterType.SECONDARY);
			let index = promotersFiltered.findIndex(
				(p) => p.externalId === iP.promoterMetaData
			)
			iP.promoterMetaData=index;
			if(iP.timeType==='TOTAL'){
				iP.totalSubsidy= 15 * candidature.ias;
			}else{
				let hours = iP.hours;
				if(hours===''){
					hours=0;
				}
				iP.totalSubsidy =      maxFixed( parseFloat(15 * candidature.ias)*parseInt(hours)/40) ;// parseFloat(15 * candidature.ias)*parseInt(hours)/40;
			}

		});

		candidatureChangeRequest.candidatureEmployeeSupportDetails = eSupportAux;


		let  eOtherSupportAux = candidatureChangeIn.employeeSupportOtherDetails?.filter(i=>i.kind === 'TEMPORARY_CHANGE');
		if(Arrays.isEmpty(eOtherSupportAux)){
			eOtherSupportAux = candidature.candidatureEmployeeSupportOtherDetails.filter(i=>i.kind === 'FINAL');
		}

		eOtherSupportAux.forEach(iP=> iP.kind='CANDIDATURE_CHANGE');
		candidatureChangeRequest.candidatureEmployeeSupportOtherDetails = eOtherSupportAux;

		return candidatureChangeRequest;
	}





	function getInitialEconomicViabilityAccretionValidation() {
		return {
			isSubRepresentationValid: null,
			isInnovationProjectValid: null,
			reasonToSubRepresentation: '',
			reasonToInnovationProject: '',
			isLevelsValid: null,
			reasonToLevels: '',
			levelsValue: 0,
			isInsideValid: null,
			reasonToInside: '',
			isIefpWorkstationsValid: null,
			reasonToIefpWorkstations: '',
			iefpWorkstationsValue: 0,
		};
	}


	function getErrorInformation(value) {
		if (navErrors.includes(value)) {
			return (
				<span>
					<FaExclamationCircle />
				</span>
			);
		}
	}

	function handleNavClick(value) {
		setNavErrors(navErrors.filter((navError) => navError !== value));
		setCurrentTab(value);
		setCandidatureChange({ ...candidatureChange });
	}

	function getEmployeeDtoDetails(employeeDetails) {
		let employeeDetailsData = [];

		employeeDetails.forEach((detail) => {
			employeeDetailsData.push({
				externalId: detail.externalId,
				promoterMetaData: detail.promoterMetaData,
				hours: detail.hours,
				timeType: detail.timeType === '-1' ? null : detail.timeType,
				totalSubsidy: detail.totalSubsidy,
				kind: detail.kind,
			});
		});

		return employeeDetailsData;
	}

	async function handleSubmit() {
		let errors = await validateAllForm();
		setNavErrors(errors);
		if (Arrays.isEmpty(errors)) {
			try {
				let dataToSend = getDataToSend();
				dataToSend.save=false;
				let queryParams = {
					toAcceptanceTerm: newAcceptanceTerm,
				};
				await submitApproveDecisionWithFinancialChanges(
					candidatureExternalId,
					externalId,
					dataToSend,
					queryParams
				);
				setSubmited(true);
			} catch (error) {
				setError(error);
			}
		}
	}


	async function handleSave() {

			try {
				let dataToSend = getDataToSend();
				dataToSend.save=true;
				let queryParams = {
					toAcceptanceTerm: newAcceptanceTerm,
				};
				await submitApproveDecisionWithFinancialChanges(
					candidatureExternalId,
					externalId,
					dataToSend,
					queryParams
				);
				setShowSaveToast(true);
			} catch (error) {
				setError(error);
			}
	}



	async function validateAllForm() {
		let errors = [];
		let errorMessages = [];

		const formikErrors = await formikPropsRef.current.validateForm();

		Object.keys(formikErrors).forEach((key) => {
			switch (key) {
				case 'candidatureEmployeeSupportDetails':
					errors.push(3);
					break;
				case 'economicViabilityAccretionValidation':
					errors.push(4);
					break;

				default:
					break;
			}
		});

		if (
			!CandidatureFormCustomValidator.validateStep3(
				formikPropsRef.current.values,
				setError,
				intl,
				errorMessages
			)
		) {
			if (!errors.includes(1)) {
				errors.push(1);
			}
		}
		if (
			!CandidatureFormCustomValidator.validateStep6(
				formikPropsRef.current.values,
				setError,
				intl,
				errorMessages
			)
		) {
			if (!errors.includes(1)) {
				errors.push(1);
			}
		}

		let submitedDocuments = newAcceptanceTerm
			? candidatureChangeDocuments.filter((d) => d.submited)
			: candidatureChangeDocuments.filter(
					(d) => d.submited && d.documentType !== documentTypes[2]
			  );

		if (
			(newAcceptanceTerm && submitedDocuments.length !== 3) ||
			(!newAcceptanceTerm && submitedDocuments.length !== 2)
		) {
			errorMessages.push(
				intl.formatMessage({
					id: 'need.documents',
				})
			);
			errors.push(6);
		}

		if (errorMessages.length > 0) {
			let message =
				errorMessages.length === 1
					? createCustomErrorMessage(errorMessages[0])
					: createCustomErrorMessage(
							'<p>' +
								intl.formatMessage({
									id: 'errors.more',
								}) +
								'</p><p> - ' +
								errorMessages.join('</p><p> - ') +
								'</p>'
					  );

			setError(message);
		}

		return errors;
	}


	function getSaveToastInformation(message) {
		return (
			<ToastContainer className='fixed-top'>
				<Toast autohide delay={3000} show={showSaveToast} onClose={() => setShowSaveToast(false)}>
					<Toast.Header>
						<strong className='me-auto text-primary'>
              <span className='mr-2'>
                <FaCheck />
              </span>
							{message}
						</strong>
					</Toast.Header>
				</Toast>
			</ToastContainer>
		);
	}

	function getDataToSend() {
		let valuesToSend = { ...formikPropsRef.current.values };

		valuesToSend.candidatureFinancialDetails = [];

		valuesToSend.candidatureEmployeeSupportDetails = getEmployeeDtoDetails(
			valuesToSend.candidatureEmployeeSupportDetails
		);

		valuesToSend.economicViabilityAccretionValidations = [
			valuesToSend.economicViabilityAccretionValidation,
		];

		return valuesToSend;
	}

	if (submited) {
		sessionStorage.setItem(
			'candidatureChangeRequestDecisionSubmited',
			submited
		);
		return (
			<Navigate
				replace
				to={`/candidatura/${candidatureExternalId}/alterarCandidatura/${externalId}/detalhe`}
			/>
		);
	}

	if (loading) {
		return <Loading />;
	}

	if (error && isNotBusinessError(error)) {
		return handleError(error);
	}

	return (
		<SubTemplate
			titleId={'candidatureChangeRequestApproval.form.title'}
			subTitleId={'candidatureChangeRequestApproval.form.subtitle'}
			hasBackButton
			centerContent
		>
			<Container>
				<Row className='mb-5'>
					<Col md='2'>
						<Nav
							variant='pills'
							defaultActiveKey={1}
							className='flex-column'
							activeKey={currentTab}
						>
							<Nav.Link
								className={`${navErrors.includes(1) ? 'activeError' : ''}`}
								eventKey={1}
								onClick={() => handleNavClick(1)}
							>
								<FormattedMessage id='economicViabilityForm.investmentPlanTab' />
								{getErrorInformation(1)}
							</Nav.Link>
							<Nav.Link
								className={`${navErrors.includes(2) ? 'activeError' : ''}`}
								eventKey={2}
								onClick={() => handleNavClick(2)}
							>
								<FormattedMessage id='economicViabilityForm.financialInvestmentTab' />
								{getErrorInformation(2)}
							</Nav.Link>
							<Nav.Link
								className={`${navErrors.includes(3) ? 'activeError' : ''}`}
								eventKey={3}
								onClick={() => handleNavClick(3)}
							>
								<FormattedMessage id='candidatureTabs.workStations' />
								{getErrorInformation(3)}
							</Nav.Link>
							<Nav.Link eventKey={4} onClick={() => handleNavClick(4)}>
								<FormattedMessage id='economicViabilityForm.accretionTab' />
								{getErrorInformation(4)}
							</Nav.Link>
							<Nav.Link eventKey={5} onClick={() => handleNavClick(5)}>
								<FormattedMessage id='candidatureForm.accretion.resume' />
								{getErrorInformation(5)}
							</Nav.Link>
							<Nav.Link eventKey={6} onClick={() => handleNavClick(6)}>
								<FormattedMessage id='candidatureTabs.attachDecision' />
								{getErrorInformation(6)}
							</Nav.Link>
						</Nav>
					</Col>
					<Col md='8'>
						<AlertError error={error} />
						{getSaveToastInformation(
							intl.formatMessage({ id: 'candidatureChangeForm.saveToastInformation' })
						)}
						<CandidatureChangeRequestApprovalForm
							candidatureChange={candidatureChange}
							documentTypes={documentTypes}
							candidatureChangeDocuments={candidatureChangeDocuments}
							setCandidatureChangeDocuments={setCandidatureChangeDocuments}
							formikPropsRef={formikPropsRef}
							error={error}
							setError={setError}
							numberOfSteps={6}
							currentStep={currentTab}
							newAcceptanceTerm={newAcceptanceTerm}
							setNewAcceptanceTerm={setNewAcceptanceTerm}
						/>
					</Col>
					<Col md='2'>
						<button
							className='btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-3'
							type='button'
							onClick={() => handleSave()}>
							<FaRegCheckCircle />
							<FormattedMessage id='all.save' />
						</button>
						<button
							className='btn btn-primary d-flex align-items-center justify-content-center w-100'
							type='button'
							onClick={() => handleSubmit()}
						>
							<FaRegCheckCircle />
							<FormattedMessage id='all.submitButtonText' />
						</button>
					</Col>
				</Row>
			</Container>
		</SubTemplate>
	);
}
