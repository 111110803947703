import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {FaEdit, FaTrashAlt} from 'react-icons/fa';
import {TiPlus} from 'react-icons/ti';
import {FormattedMessage, useIntl} from 'react-intl';
import {AlertError} from '../../../components/bootstrap/AlertError';
import {CustomPagination} from '../../../components/general/CustomPagination';
import {EmptyResults} from '../../../components/general/EmptyResults';
import Loading from '../../../components/general/Loading';
import {SubTemplate} from '../../../components/general/SubTemplate';
import {HolidaysDialog} from '../../../components/holidays/HolidaysDialog';
import {createHoliday, deleteHoliday, getAllYears, getHolidays, updateHoliday,} from '../../../rest/holidays';
import {handleError, isNotBusinessError} from '../../../utils/handleError';
import {Arrays} from '../.././../utils/Arrays';

const CURRENT_YEAR = new Date().getFullYear().toString();
const MAX_RESULTS = 25;
const DEFAULT_INITIAL_PAGE = 0;

export function Holidays() {
    const intl = useIntl();

    const [year, setYear] = useState(CURRENT_YEAR);
    const [years, setYears] = useState(null);
    const [holidays, setHolidays] = useState([]);
    const [numberOfResults, setNumberOfResults] = useState(null);
    const [totalResults, setTotalResults] = useState(null);
    const [selectedHoliday, setSelectedHoliday] = useState({});

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [update, setUpdate] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [currentPage, setCurrentPage] = useState(DEFAULT_INITIAL_PAGE);

    const fetchData = async () => {
        setError(null);
        try {
            const [{data: holidays}, {data: years}] = await Promise.all([
                await getHolidays(year, currentPage, MAX_RESULTS),
                await getAllYears(),
            ]);
            setHolidays(holidays.results);
            setNumberOfResults(holidays.numberOfResults);
            setTotalResults(holidays.totalResults);
            setYears(years);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [year, currentPage]);

    const handleSubmit = async (
        holidayData,
        externalId,
        setSubmitting,
        isUpdate
    ) => {
        isUpdate
            ? changeHoliday(holidayData, externalId, setSubmitting)
            : addHoliday(holidayData, setSubmitting);
    };

    const handleDelete = async (externalId) => {
        setLoading(true);

        try {
            const {data: deletedHoliday} = await deleteHoliday(externalId);
            setHolidays(
                holidays.filter(
                    (holiday) => holiday.externalId !== deletedHoliday.externalId
                )
            );
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const addHoliday = async (holidayData, setSubmitting) => {
        setLoading(true);

        try {
            await createHoliday(holidayData);
            fetchData();
            setSubmitting(false);
        } catch (error) {
            setError(error);
        } finally {
            setShowDialog(false);
            setLoading(false);
        }
    };

    const changeHoliday = async (holidayData, externalId, setSubmitting) => {
        setLoading(true);

        try {
            const {data: updatedHoliday} = await updateHoliday(
                externalId,
                holidayData
            );

            const oldHoliday = holidays.find(
                (holiday) => holiday.externalId === updatedHoliday.externalId
            );

            const oldHolidayIndex = holidays.indexOf(oldHoliday);

            holidays[oldHolidayIndex] = updatedHoliday;

            setHolidays([...holidays]);

            setSubmitting(false);
        } catch (error) {
            setError(error);
        } finally {
            setShowDialog(false);
            setLoading(false);
        }
    };

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleEditClick = (holiday) => {
        setUpdate(true);
        setSelectedHoliday(holiday);
        setShowDialog(true);
    };

    const handleAddClick = () => {
        setUpdate(false);
        setShowDialog(true);
    };

    const handleYearChange = (e) => {
        setYear(e.target.value);
    };

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (loading) {
        return <Loading/>;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    return (
        <SubTemplate hasBackButton={true} titleId={'holidays.manage'}>
            <div className='container'>
                <Row> <Col md="2">
                    <div className='mb-4'>
                        <button
                            className='btn btn-outline-primary mb-2'
                            onClick={() => handleAddClick()}
                        >
                            <TiPlus className='mb-1 me-2' aria-hidden='true'/>
                            <FormattedMessage id='holidays.add'/>
                        </button>
                    </div>
                </Col>
                    <Col md="8">
                        <AlertError error={error} className='mt-2'/>
                        <Row className='d-flex align-items-center mt-3'>
                            <Col lg='8'>
                                {Arrays.isNotEmpty(holidays) && (
                                    <>
                                        <div>
                                            {numberOfResults} {numberOfResults === 1 ?
                                            <FormattedMessage id='all.results.lowCase.singular'/> :
                                            <FormattedMessage id='all.results.lowCase.plural'/>}
                                        </div>
                                    </>
                                )}
                            </Col>
                            <Col lg='4'>
                                <Form.Group controlId='yearsFilter'>
                                    <Form.Select
                                        as='select'
                                        name='year'
                                        value={year}
                                        onChange={handleYearChange}
                                    >
                                        <option value={-1}>
                                            {intl.formatMessage({id: 'all.years'})}
                                        </option>
                                        {years.map((year) => (
                                            <option key={year} value={year}>
                                                {intl.formatMessage({id: 'all.yearOf'})} {year}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        {Arrays.isNotEmpty(holidays) ? (
                            <ul className='results'>
                                {holidays.map((holiday) => (
                                    <li key={holiday.externalId}>
                                        <div className='row py-3'>
                                            <div className='col-md-8'>
                                                <p className='font-weight-bold mb-0'>{holiday.description}</p>
                                                <p className='mb-0'>{holiday.holidayDate}</p>
                                            </div>
                                            <div className='col-md-4 d-flex justify-content-end'>
                                                <button
                                                    onClick={() => handleDelete(holiday.externalId)}
                                                    className='btn btn-link'
                                                >
                                                    <FaTrashAlt/>
                                                </button>

                                                <button
                                                    className='btn btn-link'
                                                    onClick={() => handleEditClick(holiday)}
                                                >
                                                    <FaEdit/>
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <EmptyResults/>
                        )}
                    </Col>

                    {showDialog && update && (
                        <HolidaysDialog
                            show={showDialog}
                            handleClose={handleDialogClose}
                            handleSubmit={handleSubmit}
                            holiday={selectedHoliday}
                            isUpdate={true}
                        />
                    )}

                    {showDialog && !update && (
                        <HolidaysDialog
                            show={showDialog}
                            handleClose={handleDialogClose}
                            handleSubmit={handleSubmit}
                            isUpdate={false}
                        />
                    )}
                </Row>
                <Row>
                    <Col md='9' className='d-flex justify-content-center'>
                        {year === '-1' && (
                            <CustomPagination
                                dataPerPage={MAX_RESULTS}
                                totalData={totalResults}
                                onPageChange={onPageChange}
                                currentPage={currentPage}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </SubTemplate>
    );
}
