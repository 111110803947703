import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AppRouter from './AppRouter';
import {
  cleanAccessInfo,
  isTokenExpired,
} from './authentication/authenticationHelper';
import { apiAxios } from './axios.config';
import Loading from './components/general/Loading';
import { ConfigObject } from './config';
import { AppProvider } from './context';
import { KeycloakObject } from './keycloak';
import { getConfigs } from './rest/config';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const initializeApp = async () => {
    try {
      //Check if token is expired
      //Get configs from backend
      const { data } = await getConfigs();
      ConfigObject.save(data);
      if (data.baseUrl) {
        apiAxios.defaults.baseURL = data.baseUrl;
        axios.defaults.baseURL = data.baseUrl;
      }
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initializeApp();
  }, []);

  if (isLoading) {
    return <></>;
  }

  if (error) {
    return <div>ERROR {error}</div>;
  }

  const keycloakConfig = {
    url: ConfigObject.get().keycloakUrl,
    realm: ConfigObject.get().keycloakRealm,
    clientId: ConfigObject.get().keycloakClientId,
  };

  KeycloakObject.create(keycloakConfig);

  return (
    <ReactKeycloakProvider
      authClient={KeycloakObject.get()}
      onEvent={KeycloakObject.onEvent}
      onTokens={KeycloakObject.onTokenChanged}
    >
      <Wrapper />
    </ReactKeycloakProvider>
  );
}

function Wrapper(params) {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <Loading complex />;
  }

  if (isTokenExpired()) {
    cleanAccessInfo();
  }

  return (
    <AppProvider config={ConfigObject.get()}>
      <AppRouter />
    </AppProvider>
  );
}

export default App;
