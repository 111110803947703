import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Nav, Row, Toast, ToastContainer } from 'react-bootstrap';
import { FaCheck, FaExclamationCircle, FaRegCheckCircle, FaRegSave } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { isEntityUser } from '../../authentication/authenticationHelper';
import { AlertError } from '../../components/bootstrap/AlertError';
import { AlertSuccess } from '../../components/bootstrap/AlertSuccess';
import { AlertPriorSupportSubmissionModal } from '../../components/candidature/AlertPriorSupportSubmissionModal';
import { CandidatureForm } from '../../components/candidature/CandidatureForm';
import { CandidatureSubmissionModal } from '../../components/candidature/CandidatureSubmissionModal';
import { CandidatureWithdrawModal } from '../../components/candidature/CandidatureWithdrawModal';
import { PriorSupportMessageModal } from '../../components/candidature/PriorSupportMessageModal ';
import { PriorSupportModal } from '../../components/candidature/PriorSupportModal';
import Loading from '../../components/general/Loading';
import { SubTemplate } from '../../components/general/SubTemplate';
import { useGlobalContext } from '../../context';
import { createCustomErrorMessage } from '../../hooks/errorMessage';
import { CandidatureState } from '../../models/CandidatureState';
import { DocumentTypeDescriptor } from '../../models/DocumentTypeDescriptor';
import { PromoterType } from '../../models/PromoterType';
import {
  getCountiesByDistrictCode,
  getDistricts,
  getParishesByCountyCode
} from '../../rest/administrativeDivision';
import {
  candidatureWithdrawal,
  createDefaultCandidature,
  getCandidatureByExternalId,
  getUserCandidature,
  saveCandidature
} from '../../rest/candidature';
import { listCompanyTypologies } from '../../rest/companyTypology';
import { listDocumentsByDescriptorAndCandidatureId } from '../../rest/document';
import { listDocumentTypesByDescriptor } from '../../rest/documentType';
import { getPriorEntityAssociatedToCandidature } from '../../rest/entityCandidature';
import { getInProgressByCandidature } from '../../rest/priorSupport';
import { getPromoter } from '../../rest/promoter';
import { getPrimaryByCandidatureExternalId } from '../../rest/promotercandidature';
import { Arrays } from '../../utils/Arrays';
import { CandidatureEmployeeSupportUtils, IAS } from '../../utils/CandidatureEmployeeSupportUtils';
import { CandidatureFormCustomValidator } from '../../utils/CandidatureFormCustomValidator';
import { handleError, isNotBusinessError } from '../../utils/handleError';
import {TimeType} from "../../models/TimeType";
import {CandidatureFinancialUtils} from "../../utils/CandidatureFinancialUtils";
import * as yup from "yup";
import {CandidatureFormUtils} from "../../utils/CandidatureFormUtils";
import {maxFixed} from "../../utils/CurrencyUtils";
import {ConfigObject} from "../../config";

export function AddCandidature() {
  const intl = useIntl();

  const navigate = useNavigate();

  const location = useLocation();

  const pageState = location.state; //only defined if redirected from prior entity association page

  const entityDetails = location.state?.isEntity ? location.state : null;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submited, setSubmited] = useState(false);

  const [promoter, setPromoter] = useState(null);
  const [candidature, setCandidature] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [counties, setCounties] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [companyTypologies, setCompanyTypologies] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [candidatureDocuments, setCandidatureDocuments] = useState([]);
  const [navErrors, setNavErrors] = useState([]);
  const [showSaveToast, setShowSaveToast] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const [showPriorSupportModal, setShowPriorSupportModal] = useState(false);
  const [priorEntityContacts, setPriorEntityContacts] = useState({ show: false });
  const [showPriorEntityMessageModal, setShowPriorEntityMessageModal] = useState(false);
  const [showAlertPriorSupportSubmissionModal, setShowAlertPriorSupportSubmissionModal] =
    useState(false);
  const [lsItem, setLsItem] = useState(null);
  const formikPropsRef = useRef(null);

  const { setHasPriorSupport,setCanEditProfile } = useGlobalContext();

  function goToSubmitDocumentPriorSupport() {
    navigate('/candidatura/' + candidature.externalId + '/revisaoApoio', {
      state: { refresh: true }
    });
  }

  function checkStorageItems() {
    if (sessionStorage.getItem('priorSupportAsked')) {
      setLsItem('priorSupportAsked');
    }
    if (sessionStorage.getItem('priorSupportReview')) {
      setLsItem('priorSupportReview');
    }
    if (sessionStorage.getItem('promoterRemoved')) {
      setLsItem('promoterRemovedAdd');
    }

  }

  function goToSubmitContractPriorSupport() {
    navigate('/candidatura/' + candidature.externalId + '/contratoApoio', {
      state: { refresh: true }
    });
  }

  async function fetchData() {
    try {
      let [
        { data: promoter },
        { data: candidature },
        { data: districts },
        { data: companyTypologies },
        { data: documentTypes }
      ] = await Promise.all([
        entityDetails
          ? await getPrimaryByCandidatureExternalId(entityDetails.candidatureExternalId)
          : await getPromoter(),

        entityDetails?.candidatureExternalId
          ? await getCandidatureByExternalId(entityDetails.candidatureExternalId)
          : await getUserCandidature(),

        await getDistricts(),
        await listCompanyTypologies(),
        await listDocumentTypesByDescriptor(DocumentTypeDescriptor.CANDIDATURE)
      ]);

      checkStorageItems();

      //if candidature does not existe, create one
      if (candidature === '') {
        let res = await createDefaultCandidature();
        candidature = res.data;
        setCanEditProfile(false)
        
      }

      if (candidature) {
        const { data: candidatureDocuments } = await listDocumentsByDescriptorAndCandidatureId(
          DocumentTypeDescriptor.CANDIDATURE,
          candidature.externalId
        );
        setCandidatureDocuments(candidatureDocuments);
      }

      setPromoter(promoter);
      const initialCandidatureValues = await getInitialCandidatureValues(promoter, candidature);

      setCandidature(initialCandidatureValues);
      setDistricts(districts);
      setCompanyTypologies(companyTypologies);
      setDocumentTypes(documentTypes);
      setShowPriorSupportModal(initialCandidatureValues.canAskPriorSupport && ConfigObject.get().closed!==true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }





  useEffect(() => {
    fetchData();
  }, []);

  async function getInitialCandidatureValues(promoter, candidature) {
    try {
      let priorEntityDefined = false;
      let priorEntityName = false;

      if (candidature.parish) {
        await handleDistrictChange(candidature.parish.substring(0, 2));
        await handleCountyChange(candidature.parish.substring(0, 4));

        //if there is a parish, check if a prior support entity has been associated
        let params = { candidatureExternalId: candidature.externalId };
        let { data: associatedPriorEntity } = await getPriorEntityAssociatedToCandidature(params);

        //when there is no associated entity, there is no description field
        if (associatedPriorEntity.description !== undefined) {
          priorEntityDefined = true;
          priorEntityName = associatedPriorEntity.description;

        }
      }

      return {
        ias: parseFloat(candidature.ias),
        step: pageState !== null ? pageState.step : 1,
        externalId: candidature.externalId ? candidature.externalId : '',
        veracityDeclaration: candidature.veracityDeclaration,

        promotersMetaData: Arrays.isNotEmpty(candidature.promotersMetaData)
          ? getPromotersValues(candidature.promotersMetaData)
          : [
              {
                externalId: '',
                name: promoter.user.name,
                nif: promoter.nif,
                email: promoter.user.email,
                promoterType: PromoterType.PRIMARY,
                applyUnemploymentBenefits: '-1',
                gender:'-1',
                qualificationLevel:'-1',
                socialCapital: 0,
                voteRight:0
              }
            ],

        district: candidature.parish ? candidature.parish.substring(0, 2) : '-1',

        submissionDate:candidature.submissionDate,

        county: candidature.parish ? candidature.parish.substring(0, 4) : '-1',

        parish: candidature.parish ? candidature.parish : '-1',

        priorEntityDefined: priorEntityDefined,
        priorEntityName:priorEntityName,
        waitingDocument: candidature.waitingDocument,
        waitingContractSubmit: candidature.waitingContractSubmit,
        priorSupportFinished: candidature.priorSupportFinished,

        canAskPriorSupport: candidature.hasOwnProperty('canAskPriorSupport')
          ? candidature.canAskPriorSupport
          : true,


        candidatureDetails: candidature.candidatureDetails
          ? candidature.candidatureDetails
          : {
              companyTypology: '-1',
              cae: '',
              projectCharacterization: '',
              targetMarket: '',
              installations: '',
              possibleIncentives: '',
              artsAndCrafts: '-1',
              investmentPlanReasoning: '',
              financialInvestmentReasoning: '',
              forecastDate: null,
              wasCreated: '-1',
              registerLink:''
            },

        candidatureFinancialDetails: candidature.candidatureFinancialDetails
          ? candidature.candidatureFinancialDetails.filter(
              (financial) => financial.kind === 'PROMOTER'
            )[0]
          : {
              investmentProperties: 0,
              tangibleAssets: 0,
              intangibleAssets: 0,
              several: 0,
              pettyCash: 0,
              socialCapital: 0,
              unemploymentBenefits: 0,
              otherBenefits: 0,
              bankLoans: 0,
              partnerLoans: 0,
              supplierCredits: 0,
              otherForeignCapital: 0,
              investmentSupport: 0,
              applyNonRefundableSubsidy: '-1',
              interestFreeLoan: 0,
              nonRefundableSubsidy: 0,
              jobCreationSubsidy: 0,
              applyUnemploymentBenefits: '-1',
              kind: 'PROMOTER',
              totalJobSubsidy: 0
            },

        candidatureEmployeeSupportDetails: candidature.candidatureEmployeeSupportDetails
          ? getCandidatureEmployeeSupportDetailsValues(
              candidature.candidatureEmployeeSupportDetails,candidature.promotersMetaData, candidature.ias
            )
          : [],

        candidatureEmployeeSupportOtherDetails: candidature.candidatureEmployeeSupportOtherDetails,

        investmentPlanDetails: candidature.investmentPlanDetails,
        financialInvestmentDetails: candidature.financialInvestmentDetails,

        otherIncentives: candidature.otherIncentives ? candidature.otherIncentives : [],

        sellInformationList: candidature.sellInformationList ? candidature.sellInformationList : [],

        provideInformationList: candidature.provideInformationList
          ? candidature.provideInformationList
          : [],

        peopleBoardList: candidature.peopleBoardList ? candidature.peopleBoardList : [],

        otherChargeList: candidature.otherChargeList ? candidature.otherChargeList : [],

        forecastDemonstrationList: candidature.forecastDemonstrationList
          ? candidature.forecastDemonstrationList
          : [],

        forecastBalanceList: candidature.forecastBalanceList,

        peopleRemunerationList: candidature.peopleRemunerationList
          ? candidature.peopleRemunerationList
          : [],

        amortizationExpensesList: candidature.amortizationExpensesList
          ? candidature.amortizationExpensesList
          : [],

        earlyAmortizationList: candidature.earlyAmortizationList
          ? candidature.earlyAmortizationList
          : [],

        state: candidature.state ? candidature.state : CandidatureState.DRAFT,

        step8Documents: [],

        step10Documents: []
      };
    } catch (error) {
      setError(error);
    }
  }

  function getPromotersValues(promotersMetaData) {
    let values = [];

    promotersMetaData.forEach((pm) => {
      values.push(pm);
    });

    return values;
  }

  function getCandidatureEmployeeSupportDetailsValues(employeeDetails,promotersMetaData, iasValue) {
    let values = [];

    let promotersFiltered = promotersMetaData.filter(p=> p.promoterType===PromoterType.PRIMARY || p.promoterType===PromoterType.SECONDARY)

    employeeDetails.forEach((detail) => {

      let valueToPush = {};

      valueToPush = {
        externalId: detail.externalId,
        promoterMetaData: detail.promoterMetaData? promotersFiltered.findIndex(
            (p) => p.externalId === detail.promoterMetaData
        )  :'-1',
        hours:detail.hours,
        timeType: detail.timeType? detail.timeType : '-1'

      };
      let hours = detail.hours
      if(hours===''){
        hours=0;
      }
      //TODO: Se For Candidatura IAS atual
      //TODO: Se For Alteração Candidatura IAS fully approved
      let totalSubsidyValue = maxFixed( (detail.promoterMetaData ==='-1' || detail.timeType==='-1' ) ? 0 : (detail.timeType===TimeType.PARTIAL? ( parseFloat(15 * iasValue)*parseInt(hours)/40) : 15*iasValue)    );

      valueToPush.totalSubsidy = parseFloat(totalSubsidyValue);

      values.push(valueToPush);
    });

    return values;
  }

  function getEmployeeDtoDetails(employeeDetails) {
    let employeeDetailsData = [];
    employeeDetails.forEach((detail) => {
      employeeDetailsData.push({
        externalId: detail.externalId,
        promoterMetaData: detail.promoterMetaData,
        hours:detail.hours,
        timeType:detail.timeType==='-1'? null : detail.timeType,
        totalSubsidy: detail.totalSubsidy
      });
    });

    return employeeDetailsData;
  }

  function validateDtoBooleanValues(dtoValues) {
    if (dtoValues.candidatureDetails.artsAndCrafts === '-1') {
      dtoValues.candidatureDetails.artsAndCrafts = null;
    }
    if (dtoValues.candidatureDetails.wasCreated === '-1') {
      dtoValues.candidatureDetails.wasCreated = null;
    }
    if (dtoValues.candidatureFinancialDetails[0].applyNonRefundableSubsidy === '-1') {
      dtoValues.candidatureFinancialDetails[0].applyNonRefundableSubsidy = null;
    }
    dtoValues.promotersMetaData.forEach((pm) => {
      if (pm.applyUnemploymentBenefits === '-1') {
        pm.applyUnemploymentBenefits = null;
      }
      if(pm.gender==='-1'){
        pm.gender=null;
      }
      if(pm.qualificationLevel ==='-1'){
        pm.qualificationLevel= null;
      }
    });

  }

  function checkNASellInformation(dtoValues) {
    let sellInformation = dtoValues.sellInformationList.find((sil) => sil.type === 'CONSUME');

    if (sellInformation.nonApplicable) {
      sellInformation.sellInformationLines = [];
    }
  }

  async function handleDistrictChange(districtCode) {
    try {
      const { data: counties } = await getCountiesByDistrictCode(districtCode);
      setCounties(counties);
      setParishes([]);

      if (candidature) {
        candidature.parish = '-1';
        setCandidature({ ...candidature });
      }
    } catch (error) {
      setError(error);
    }
  }

  async function handleCountyChange(countyCode) {
    try {
      const { data: parishes } = await getParishesByCountyCode(countyCode);
      setParishes(parishes);

      if (candidature) {
        candidature.parish = '-1';
        setCandidature({ ...candidature });
      }
    } catch (error) {
      setError(error);
    }
  }

  function handleNavClick(value) {
    setNavErrors(navErrors.filter((navError) => navError !== value));
    candidature.step = value;
    setCandidature({ ...candidature });
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  function handleNavError(error) {
    let errors = error.response.data.message.split(',').map((value) => Number.parseInt(value));
    setNavErrors(errors);
  }

  function handleGoBack(values, setFieldValue) {
    setError(null);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setFieldValue('step', values.step - 1);
    candidature.step = values.step - 1;
    setCandidature({ ...candidature });
  }

  async function handleSave(switchStep, preSavePriorEntity, parish) {
    let values = formikPropsRef.current.values;

    try {
      let reqParams = { preSavePriorEntity: false };

      const candidatureAux = { ...values };
      candidatureAux.candidatureFinancialDetails = [values.candidatureFinancialDetails];
      validateDtoBooleanValues(candidatureAux);
      candidatureAux.candidatureEmployeeSupportDetails = getEmployeeDtoDetails(
        values.candidatureEmployeeSupportDetails
      );

      if(candidatureAux.candidatureDetails.forecastDate ===''){
        candidatureAux.candidatureDetails.forecastDate = null
      }

      checkNASellInformation(candidatureAux);
      candidatureAux.validate = false;
      if (switchStep) {
        candidatureAux.setByStep = true;
      } else {
        candidatureAux.setByStep = false;
      }

      if (preSavePriorEntity !== undefined) {
        reqParams.preSavePriorEntity = preSavePriorEntity; //which has the "true" value
        candidatureAux.parish = parish;
        await saveCandidature(candidatureAux, reqParams);

        candidature.parish = parish;
        setCandidature({ ...candidature });

        return;
      }


      const { data: candidatureData } = await saveCandidature(candidatureAux);

      formikPropsRef.current.setFieldValue('externalId', candidatureData.externalId);

      if (switchStep) {
        candidature.step = values.step + 1;
        setCandidature({ ...candidature });
      }

      setShowSaveToast(true);
    } catch (error) {
      setError(error);
    }
  }


  async function handleValidateAll() {

    let errors = await validateAllForm();
    setNavErrors(errors);
    if(errors.length===0){
      setLsItem('noErrors');
      setError(null)
    }
  }



  async function handleSubmitAll() {
    let errors = await validateAllForm();
    setNavErrors(errors);

    const { data: priorSupport } = await getInProgressByCandidature(candidature.externalId);

    if (priorSupport) {
      switch (priorSupport.state) {
        case 'ASKED':
          setShowAlertPriorSupportSubmissionModal(true);
          return;
        case 'WAIT_DOC':
          setShowAlertPriorSupportSubmissionModal(true);
          return;
        case 'ACCEPTED':
          setError(
            createCustomErrorMessage(
              intl.formatMessage({
                id: 'errors.candidatureForm.acceptedPriorSupport'
              })
            )
          );
          return;

        default:
          break;
      }
    }

    setShowSubmissionModal(true);
  }

  async function submitAll(user, password, setModalError) {
    try {
      let values = formikPropsRef.current.values;
      const candidatureAux = { ...values };
      candidatureAux.candidatureFinancialDetails = [values.candidatureFinancialDetails];
      candidatureAux.candidatureEmployeeSupportDetails = getEmployeeDtoDetails(
        values.candidatureEmployeeSupportDetails
      );
      candidatureAux.validate = true;
      candidatureAux.setByStep = false;
      candidatureAux.user = user;
      candidatureAux.password = password;

      if(values.candidatureDetails.forecastDate ===''){
        values.candidatureDetails.forecastDate = null
      }

      await saveCandidature(candidatureAux);

      setNavErrors([]);
      setSubmited(true);
    } catch (error) {
      if (error.response.data.exception === 'ValidationException') {
        handleNavError(error);
      } else {
        setModalError(error);
      }
    }
  }

  function isEmpty(value){
    return value=== '' || value=== null ||  value=== -1 || value=== undefined;
  }

  async function validateStep14Fields(formikErrors,values){

    if(await CandidatureFinancialUtils.getFreeLoans(values)>0){
      let errors2 = formikErrors;
      let flag = false;
      if(isEmpty(values.earlyAmortizationList[0].reasonToEarlyAmortization)){
        errors2['earlyAmortizationList'] =  [{reasonToEarlyAmortization: <FormattedMessage id='errors.fieldRequiredText' />}];
        flag = true;
      }


      if(isEmpty(values.earlyAmortizationList[0].appliedEarlyAmortization)){
        if(!flag){
          errors2['earlyAmortizationList'] =  [{appliedEarlyAmortization: <FormattedMessage id='errors.fieldRequiredText' />}];
          flag = true;
        }else{
          errors2['earlyAmortizationList'][0]['appliedEarlyAmortization']= <FormattedMessage id='errors.fieldRequiredText' />;
        }
      }


      if(isEmpty(values.earlyAmortizationList[0].appliedDeferralPeriodBenefits)){
        if(!flag){
          errors2['earlyAmortizationList'] =  [{appliedDeferralPeriodBenefits: <FormattedMessage id='errors.fieldRequiredText' />}];
          flag = true;
        }else{
          errors2['earlyAmortizationList'][0]['appliedDeferralPeriodBenefits']= <FormattedMessage id='errors.fieldRequiredText' />;
        }
      }else if(values.earlyAmortizationList[0].appliedDeferralPeriodBenefits !== true){

        if(isEmpty(values.earlyAmortizationList[0].initialYear)){
          if(!flag){
            errors2['earlyAmortizationList'] =  [{initialYear: <FormattedMessage id='errors.fieldRequiredText' />}];
            flag = true;
          }else{
            errors2['earlyAmortizationList'][0]['initialYear']= <FormattedMessage id='errors.fieldRequiredText' />;
          }
        }else{
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          if(parseInt(values.earlyAmortizationList[0].initialYear)<currentDate.getFullYear()){
            if(!flag){
              errors2['earlyAmortizationList'] =  [{initialYear: <FormattedMessage id='errors.candidatureForm.initialYear' />}];
              flag = true;
            }else{
              errors2['earlyAmortizationList'][0]['initialYear']= <FormattedMessage id='errors.candidatureForm.initialYear' />;
            }
          }
        }
      }




      if(isEmpty(values.earlyAmortizationList[0].appliedDeferralPeriodBenefits)){
        if(!flag){
          errors2['earlyAmortizationList'] =  [{appliedDeferralPeriodBenefits: <FormattedMessage id='errors.fieldRequiredText' />}];
          flag = true;
        }else{
          errors2['earlyAmortizationList'][0]['appliedDeferralPeriodBenefits']= <FormattedMessage id='errors.fieldRequiredText' />;
        }
      }else if(values.earlyAmortizationList[0].appliedDeferralPeriodBenefits !== true){

        if(isEmpty(values.earlyAmortizationList[0].initialYear)){
          if(!flag){
            errors2['earlyAmortizationList'] =  [{initialYear: <FormattedMessage id='errors.fieldRequiredText' />}];
            flag = true;
          }else{
            errors2['earlyAmortizationList'][0]['initialYear']= <FormattedMessage id='errors.fieldRequiredText' />;
          }
        }else{
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
          if(parseInt(values.earlyAmortizationList[0].initialYear)<currentDate.getFullYear()){
            if(!flag){
              errors2['earlyAmortizationList'] =  [{initialYear: <FormattedMessage id='errors.candidatureForm.initialYear' />}];
              flag = true;
            }else{
              errors2['earlyAmortizationList'][0]['initialYear']= <FormattedMessage id='errors.candidatureForm.initialYear' />;
            }
          }
        }
      }else{
        if(isEmpty(values.earlyAmortizationList[0].deferralPeriod)){
          if(!flag){
            errors2['earlyAmortizationList'] =  [{deferralPeriod: <FormattedMessage id='errors.fieldRequiredText' />}];
            flag = true;
          }else{
            errors2['earlyAmortizationList'][0]['deferralPeriod']= <FormattedMessage id='errors.fieldRequiredText' />;
          }
        }else{
           if(values.earlyAmortizationList[0].deferralPeriod<0){
             if(!flag){
               errors2['earlyAmortizationList'] =  [{deferralPeriod: <FormattedMessage id='errors.notPositive' />}];
               flag = true;
             }else{
               errors2['earlyAmortizationList'][0]['deferralPeriod']= <FormattedMessage id='errors.notPositive' />;
             }
           }else if(values.earlyAmortizationList[0].deferralPeriod>24){
             if(!flag){
               errors2['earlyAmortizationList'] =  [{deferralPeriod: <FormattedMessage id='errors.candidatureForm.deferralPeriod' />}];
               flag = true;
             }else{
               errors2['earlyAmortizationList'][0]['deferralPeriod']= <FormattedMessage id='errors.candidatureForm.deferralPeriod' />;
             }
           }
        }
      }

      if(flag ===true){
        formikPropsRef.current.setErrors(errors2);
      }


    }
  }

  function updateStrutureSellInformation(errors2,index,index2,indexSellInformation){
    if(errors2['sellInformationList']===undefined){
      errors2['sellInformationList'] = [];
    }
    if(errors2['sellInformationList'][indexSellInformation]===undefined){
      errors2['sellInformationList'][indexSellInformation]= {};
    }

    if(errors2['sellInformationList'][indexSellInformation].sellInformationLines === undefined){
      errors2['sellInformationList'][indexSellInformation].sellInformationLines = [];
    }

    if(errors2['sellInformationList'][indexSellInformation]['sellInformationLines'][index] === undefined){
      errors2['sellInformationList'][indexSellInformation].sellInformationLines[index] = [];
    }
    if(errors2['sellInformationList'][indexSellInformation]['sellInformationLines'][index].sellInformationLineYears === undefined){
      errors2['sellInformationList'][indexSellInformation].sellInformationLines[index].sellInformationLineYears = [];
    }

    if(errors2['sellInformationList'][indexSellInformation]['sellInformationLines'][index].sellInformationLineYears[index2] === undefined){
      errors2['sellInformationList'][indexSellInformation].sellInformationLines[index].sellInformationLineYears[index2] = {};
    }
    return errors2;
  }


  function updateStrutureForeCastBalance(errors2){
    if(errors2['forecastBalanceList']===undefined){
      errors2['forecastBalanceList'] = [];
    }
    if(errors2['forecastBalanceList'][0]===undefined){
      errors2['forecastBalanceList'][0]= {};
    }
    return errors2;
  }

  async function validateStep9Fields(formikErrors,values){
    await validateSellInformation(formikErrors, values, 1);
  }

  async function validateStep8Fields(formikErrors,values){
    await validateSellInformation(formikErrors, values, 0);
  }

  async function validateStep17Fields(formikErrors,values){
    let errors2 = formikErrors;
    let flag = false;

    if (CandidatureFormUtils.isProjectTotalValueAboveLimitValue(values)) {
      if(values.forecastBalanceList[0].reasonToForecastBalance==='' || values.forecastBalanceList[0].reasonToForecastBalance===null){
        errors2= updateStrutureForeCastBalance(errors2);
        errors2.forecastBalanceList[0].reasonToForecastBalance= <FormattedMessage id='errors.fieldRequiredText' />;
        flag=true;
      }

      if(values.forecastBalanceList[0].type==='-1' || values.forecastBalanceList[0].type===null){
        errors2= updateStrutureForeCastBalance(errors2);
        errors2.forecastBalanceList[0].type= <FormattedMessage id='errors.fieldRequiredText' />;
        flag=true;
      }

    }


    if(flag ===true){
      formikPropsRef.current.setErrors(errors2);
    }

  }


  async function validateSellInformation(formikErrors,values,indexSellInformation){
    let errors2 = formikErrors;
    let flag = false;

    values.sellInformationList[indexSellInformation].sellInformationLines.forEach((line,index)=>{
      line.sellInformationLineYears.forEach((lineYear, index2)=>{

          if(parseFloat(lineYear.quantity)===0 && parseFloat(lineYear.unitPrice) !==0){
            errors2 = updateStrutureSellInformation(errors2,index,index2,indexSellInformation);
            errors2['sellInformationList'][indexSellInformation].sellInformationLines[index].sellInformationLineYears[index2].quantity =  <FormattedMessage id='errors.fieldRequiredText' />;
            flag = true;
          }

        if(parseFloat(lineYear.quantity)!==0 && parseFloat(lineYear.unitPrice) ===0){
          errors2 = updateStrutureSellInformation(errors2,index,index2,indexSellInformation);
          errors2['sellInformationList'][indexSellInformation].sellInformationLines[index].sellInformationLineYears[index2].unitPrice= <FormattedMessage id='errors.fieldRequiredText' />;
          flag=true;
        }


      })

    });
      if(flag ===true){
        formikPropsRef.current.setErrors(errors2);
      }

    }






  async function validateAllForm() {
    let errors = [];

    const formikErrors = await formikPropsRef.current.validateForm();
    console.log(formikErrors)
    console.log(formikPropsRef.current.values)

    await validateStep9Fields(formikErrors, formikPropsRef.current.values);
    await validateStep8Fields(formikErrors, formikPropsRef.current.values);
    await validateStep14Fields(formikErrors, formikPropsRef.current.values);
    await validateStep17Fields(formikErrors, formikPropsRef.current.values);









    console.log(formikErrors)
    console.log("Values:",formikPropsRef)


    Object.keys(formikErrors).forEach((key) => {

      switch (key) {
        case 'promotersMetaData':
          formikErrors[key].forEach((pMError) => {
            if(pMError!== undefined){
              let keys = Object.keys(pMError);
              if (keys.length > 0) {
                errors.push(1);
              }
            }
          });
          break;
        case 'candidatureDetails':
          let candidatureDetailsKeys = Object.keys(formikErrors[key]);
          if (candidatureDetailsKeys.includes('investmentPlanReasoning')) {
            errors.push(5);
            candidatureDetailsKeys = candidatureDetailsKeys.filter(
              (key) => key !== 'investmentPlanReasoning'
            );
          }
          if (candidatureDetailsKeys.includes('financialInvestmentReasoning')) {
            candidatureDetailsKeys = candidatureDetailsKeys.filter(
              (key) => key !== 'financialInvestmentReasoning'
            );
            errors.push(6);
          }
          if (candidatureDetailsKeys.length > 0) {
            errors.push(2);
          }
          break;

        case 'district':
        case 'county':
        case 'parish':
          errors.push(2);
          break;

        case 'candidatureFinancialDetails':
          errors.push(5);
          break;

        case 'otherIncentives':
          errors.push(7);
          break;

        case 'sellInformationList':
          if (formikErrors.sellInformationList[0]) {
            errors.push(8);
          }

          if (formikErrors.sellInformationList[1]) {
            errors.push(9);
          }
          break;

        case 'provideInformationList':
          errors.push(10);
          break;

        case 'peopleBoardList':
          errors.push(11);
          break;

        case 'peopleRemunerationList':
          errors.push(12);
          break;

        case 'amortizationExpensesList':
          errors.push(13);
          break;

        case 'earlyAmortizationList':
          errors.push(14);
          break;

        case 'otherChargeList':
          errors.push(15);
          break;
        case 'forecastDemonstrationList':
          errors.push(16);
          break;

        case 'candidatureEmployeeSupportDetails':
          errors.push(3);
          break;
        case 'candidatureEmployeeSupportOtherDetails':
          errors.push(4);
          break;
        case 'forecastBalanceList':
          errors.push(17);
          break;
        case 'veracityDeclaration':
          errors.push(21);
          break;

        default:
          break;
      }
    });


    let errorMessages = [];



    if (
      !CandidatureFormCustomValidator.validateStep1(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      if (!errors.includes(1)) {
        errors.push(1);
      }
    }

    if (
      !CandidatureFormCustomValidator.validateStep5(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      if (!errors.includes(5)) {
        errors.push(5);
      }
    }

    if (
      !CandidatureFormCustomValidator.validateStep6(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      if (!errors.includes(6)) {
        errors.push(6);
      }
    }

    if (
      !CandidatureFormCustomValidator.validateStep8(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      errors.push(8);
    }

    if (
        !CandidatureFormCustomValidator.validateStep9(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      errors.push(9);
    }

    if (
      !CandidatureFormCustomValidator.validateStep10(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      errors.push(10);
    }

    if (
      !CandidatureFormCustomValidator.validateStep11(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      errors.push(11);
    }

    if (
      !CandidatureFormCustomValidator.validateStep12(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      errors.push(12);
    }

    if (
      !await CandidatureFormCustomValidator.validateStep14(formikPropsRef.current.values, setError, intl, errorMessages)
    ) {
      errors.push(14);
    }

    if (
        !CandidatureFormCustomValidator.validateStep15(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      errors.push(15);
    }

    if (
        !CandidatureFormCustomValidator.validateStep16(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      errors.push(16);
    }



    if (
        !CandidatureFormCustomValidator.validateStep17(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      errors.push(17);
    }

    if (
      !CandidatureFormCustomValidator.validateStep3(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      errors.push(3);
    }

    if (
      !CandidatureFormCustomValidator.validateStep19(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      errors.push(19);
    }

    if (
      !CandidatureFormCustomValidator.validateStep20(formikPropsRef.current.values, setError, intl,errorMessages)
    ) {
      errors.push(20);
    }

    if(errorMessages.length>0){
      let message = (errorMessages.length === 1) ? createCustomErrorMessage(errorMessages[0]) : createCustomErrorMessage( '<p>'+  intl.formatMessage({
        id: 'errors.more'
      })+'</p><p> - '+ errorMessages.join('</p><p> - ')+'</p>') ;
      setError(message);
    }


    return errors;
  }

  function getErrorInformation(value) {
    if (navErrors.includes(value)) {
      return (
        <span>
          <FaExclamationCircle />
        </span>
      );
    }
  }

  function getSaveToastInformation(message) {
    return (
      <ToastContainer className='fixed-top'>
        <Toast autohide delay={3000} show={showSaveToast} onClose={() => setShowSaveToast(false)}>
          <Toast.Header>
            <strong className='me-auto text-primary'>
              <span className='mr-2'>
                <FaCheck />
              </span>
              {message}
            </strong>
          </Toast.Header>
        </Toast>
      </ToastContainer>
    );
  }

  async function withdrawSubmitHandler(user, password, setModalError) {
    try {
      let authenticationCredentials = {
        user: user,
        password: password
      };
      let isCancel = candidature.state === CandidatureState.DRAFT;
      await candidatureWithdrawal(authenticationCredentials, candidature.externalId);
      setHasPriorSupport(false);
      if(isCancel === true){
        sessionStorage.setItem('candidatureCancel', true);
      }else{
        sessionStorage.setItem('candidatureWithdrawed', true);
      }
      navigate('/candidatura');
    } catch (error) {
      setModalError(error);
    }
  }

  if (submited) {
    sessionStorage.setItem('candidatureSubmited', submited);
    return <Navigate replace to={'/candidatura'} />;
  }

  if (loading) {
    return <Loading />;
  }


  if (error && isNotBusinessError(error)) {
    return handleError(error);
  }


  return (
    <SubTemplate
      titleId= {candidature.submissionDate !== undefined ? 'candidatureForm.title.change':'candidatureForm.title'}
      subTitleId='candidatureForm.subTitle'
      centerContent>
      <Container>
        <PriorSupportModal
          show={showPriorSupportModal && !isEntityUser() }
          handleClose={() => setShowPriorSupportModal(false)}
          candidature={candidature}
        />
        {lsItem && (
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <div className='mx-2 mb-4'>
                <AlertSuccess lsItem={lsItem} />
              </div>
            </Col>
          </Row>
        )}
        <CandidatureSubmissionModal
          show={showSubmissionModal}
          handleClose={() => setShowSubmissionModal(false)}
          errors={navErrors}
          submitAll={submitAll}
        />
        <CandidatureWithdrawModal
          show={showWithdrawModal}
          handleClose={() => setShowWithdrawModal(false)}
          withdrawSubmitHandler={withdrawSubmitHandler}
          candidature={candidature}
        />
        <AlertPriorSupportSubmissionModal
          show={showAlertPriorSupportSubmissionModal}
          handleClose={() => setShowAlertPriorSupportSubmissionModal(false)}
          setShowSubmissionModal={setShowSubmissionModal}
        />
        <Row className='mb-5'>
          <Col md='2'>
            <Nav
              variant='pills'
              defaultActiveKey={1}
              className='flex-column'
              activeKey={candidature?.step}>
              <Nav.Link
                className={`${navErrors.includes(1) ? 'activeError' : ''}`}
                eventKey={1}
                onClick={() => handleNavClick(1)}>
                <FormattedMessage id='home.text11' />
                {getErrorInformation(1)}
              </Nav.Link>
              <Nav.Link
                className={`${navErrors.includes(2) ? 'activeError' : ''}`}
                eventKey={2}
                onClick={() => handleNavClick(2)}>
                <FormattedMessage id='candidatureTabs.project' />
                {getErrorInformation(2)}
              </Nav.Link>
              <Nav.Link eventKey={3} onClick={() => handleNavClick(3)}>
                <FormattedMessage id='candidatureTabs.workStations' />
                {getErrorInformation(3)}
              </Nav.Link>
              <Nav.Link eventKey={4} onClick={() => handleNavClick(4)}>
                <FormattedMessage id='candidatureForm.accretion.title' />
                {getErrorInformation(4)}
              </Nav.Link>
              <Nav.Link
                className={`${navErrors.includes(5) ? 'activeError' : ''}`}
                eventKey={5}
                onClick={() => handleNavClick(5)}>
                <FormattedMessage id='economicViabilityForm.investmentPlanTab' />
                {getErrorInformation(5)}
              </Nav.Link>
              <Nav.Link
                className={`${navErrors.includes(6) ? 'activeError' : ''}`}
                eventKey={6}
                onClick={() => handleNavClick(6)}>
                <FormattedMessage id='economicViabilityForm.financialInvestmentTab' />
                {getErrorInformation(6)}
              </Nav.Link>
              <Nav.Link eventKey={7} onClick={() => handleNavClick(7)}>
                <FormattedMessage id='candidatureTabs.otherIncentives' />
                {getErrorInformation(7)}
              </Nav.Link>
              <Nav.Link eventKey={8} onClick={() => handleNavClick(8)}>
                <FormattedMessage id='candidatureTabs.sellInformation' />
                {getErrorInformation(8)}
              </Nav.Link>
              <Nav.Link eventKey={9} onClick={() => handleNavClick(9)}>
                <FormattedMessage id='candidatureTabs.consumeInformation' />
                {getErrorInformation(9)}
              </Nav.Link>
              <Nav.Link eventKey={10} onClick={() => handleNavClick(10)}>
                <FormattedMessage id='candidatureTabs.provideInformation' />
                {getErrorInformation(10)}
              </Nav.Link>
              <Nav.Link eventKey={11} onClick={() => handleNavClick(11)}>
                <FormattedMessage id='candidatureTabs.peopleBoard' />
                {getErrorInformation(11)}
              </Nav.Link>
              <Nav.Link eventKey={12} onClick={() => handleNavClick(12)}>
                <FormattedMessage id='candidatureTabs.peopleRemuneration' />
                {getErrorInformation(12)}
              </Nav.Link>
              <Nav.Link eventKey={13} onClick={() => handleNavClick(13)}>
                <FormattedMessage id='candidatureTabs.amortizationExpenses' />
                {getErrorInformation(13)}
              </Nav.Link>
              <Nav.Link eventKey={14} onClick={() => handleNavClick(14)}>
                <FormattedMessage id='candidatureTabs.earlyAmortization' />
                {getErrorInformation(14)}
              </Nav.Link>
              <Nav.Link eventKey={15} onClick={() => handleNavClick(15)}>
                <FormattedMessage id='candidatureTabs.otherCharges.title' />
                {getErrorInformation(15)}
              </Nav.Link>
              <Nav.Link eventKey={16} onClick={() => handleNavClick(16)}>
                <FormattedMessage id='candidatureTabs.accordion.forecastDemonstration' />
                {getErrorInformation(16)}
              </Nav.Link>
              <Nav.Link eventKey={17} onClick={() => handleNavClick(17)}>
                <FormattedMessage id='candidatureTabs.forecastBalance' />
                {getErrorInformation(17)}
              </Nav.Link>

              <Nav.Link eventKey={18} onClick={() => handleNavClick(18)}>
                <FormattedMessage id='candidatureTabs.supportDocuments' />
                {getErrorInformation(18)}
              </Nav.Link>
              <Nav.Link eventKey={19} onClick={() => handleNavClick(19)}>
                <FormattedMessage id='all.requiredDocuments2' />
                {getErrorInformation(19)}
              </Nav.Link>
              <Nav.Link eventKey={20} onClick={() => handleNavClick(20)}>
                <FormattedMessage id='candidatureTabs.contributoryDeclarations' />
                {getErrorInformation(20)}
              </Nav.Link>
              <Nav.Link eventKey={21} onClick={() => handleNavClick(21)}>
                <FormattedMessage id='candidatureTabs.veracityDeclaration' />
                {getErrorInformation(21)}
              </Nav.Link>
            </Nav>
          </Col>
          <Col md='8'>
            <AlertError error={error} />
            {getSaveToastInformation(
              intl.formatMessage({ id: 'candidatureForm.saveToastInformation' })
            )}
            <CandidatureForm
              candidature={candidature}
              districts={districts}
              counties={counties}
              parishes={parishes}
              companyTypologies={companyTypologies}
              documentTypes={documentTypes}
              candidatureDocuments={candidatureDocuments}
              setCandidatureDocuments={setCandidatureDocuments}
              entityDetails={entityDetails}
              formikPropsRef={formikPropsRef}
              handleDistrictChange={handleDistrictChange}
              handleCountyChange={handleCountyChange}
              handleSave={handleSave}
              handleGoBack={handleGoBack}
              error={error}
              setError={setError}
              numberOfSteps={21}
            />
          </Col>
          <Col md='2'>
            <div>
              <fieldset
                disabled={entityDetails?.isEntity || (candidature.state !== CandidatureState.DRAFT && candidature.state !== CandidatureState.WITHDRAW)}>
                {(candidature.canAskPriorSupport &&  ConfigObject.get().closed!==true) && (
                  <button
                    className='btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-4'
                    type='button'
                    onClick={() => {
                      navigate('/apoioPrevio', {
                        state: {
                          parish: formikPropsRef.current.values.parish,
                          step: formikPropsRef.current.values.step
                        }
                      });
                    }}>
                    <FormattedMessage id='candidatureForm.priorSupport' />
                  </button>
                )}

                {candidature.waitingDocument && (
                  <div>
                    <button
                      className='btn btn-primary d-flex align-items-center justify-content-center mb-4 w-100'
                      type='button'
                      onClick={goToSubmitDocumentPriorSupport}>
                      <FormattedMessage id='candidatureForm.submitReviewPriorSupport' />
                    </button>
                  </div>
                )}

                {candidature.waitingContractSubmit && (
                  <div>
                    <button
                      className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                      type='button'
                      onClick={goToSubmitContractPriorSupport}>
                      <FormattedMessage id='candidatureForm.submitContractPriorSupport' />
                    </button>
                  </div>
                )}

                {(candidature.priorEntityDefined && !candidature.priorSupportFinished) && (
                  <button
                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                    type='button'
                    onClick={() => {
                      setShowPriorEntityMessageModal(true);
                    }}>
                    <FormattedMessage id='candidatureForm.showPriorSupportContacts' />
                  </button>
                )}
                {CandidatureState.statesBeforeVALIDATION().includes(candidature.state) && (
                  <button
                    className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100 mt-3'
                    type='button'
                    onClick={() => setShowWithdrawModal(true)}>
                    <FormattedMessage id={candidature.state === CandidatureState.DRAFT ? 'all.cancel':'all.candidatureWithdrawal' } />
                  </button>
                )}

                <button
                  className='btn btn-outline-primary d-flex align-items-center justify-content-center mb-4 w-100'
                  type='button'
                  onClick={() => handleSave(false)}>
                  <FaRegSave />
                  <FormattedMessage id='all.save' />
                </button>

                <button
                    className='btn btn-outline-primary d-flex align-items-center justify-content-center w-100 mb-4'
                    type='button'
                    onClick={() => handleValidateAll()}>
                  <FaRegCheckCircle />
                  <FormattedMessage id='all.validateButtonText' />
                </button>
                

                {(ConfigObject.get().closed !==true || CandidatureState.WITHDRAW=== candidature.state) &&
                <button
                    className='btn btn-primary d-flex align-items-center justify-content-center w-100'
                    type='button'
                    onClick={() => handleSubmitAll()}>
                  <FaRegCheckCircle />
                  <FormattedMessage id='all.submitButtonText' />
                </button>

                }


                <PriorSupportMessageModal
                  show={showPriorEntityMessageModal}
                  setShow={setShowPriorEntityMessageModal}
                  candidatureExternalId={candidature.externalId}
                  entityName={candidature.priorEntityName}

                />
              </fieldset>
            </div>
          </Col>
        </Row>
      </Container>
    </SubTemplate>
  );
}
