import React, {useEffect, useState} from 'react';
import {FaEdit} from 'react-icons/fa';
import {TiPlus} from 'react-icons/ti';
import {FormattedMessage} from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {AlertError} from '../../../components/bootstrap/AlertError';
import Loading from '../../../components/general/Loading';
import {SubTemplate} from '../../../components/general/SubTemplate';
import {handleError, isNotBusinessError} from '../../../utils/handleError';
import {Arrays} from "../../../utils/Arrays";
import {EmptyResults} from "../../../components/general/EmptyResults";
import {IASDialog} from "../../../components/ias/IASDialog";
import {addIAS, getIAS, updateIAS} from "../../../rest/ias";

const MAX_RESULTS = 25;
const DEFAULT_INITIAL_PAGE = 0;

export function ListIAS() {
    const [ias, setIas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [numberOfResults, setNumberOfResults] = useState(null);
    const [totalResults, setTotalResults] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedIAS, setSelectedIAS] = useState();
    const [update, setUpdate] = useState(false);

    const fetchData = async () => {
        setError(null);
        try {
            const {data: ias} = await getIAS();
            setIas(ias.results)
            setNumberOfResults(ias.numberOfResults);
            setTotalResults(ias.totalResults);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const handleAddClick = () => {
        setUpdate(false);
        setShowDialog(true);
    };

    const handleEditClick = (ias) => {
        setUpdate(true);
        setSelectedIAS(ias);
        setShowDialog(true);
    };

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleSubmit = async (
        iasData,
        id,
        setSubmitting,
        isUpdate
    ) => {
        isUpdate
            ? await changeIas(iasData, id, setSubmitting)
            : await createIas(iasData, setSubmitting);
    };

    const createIas = async (iasData, setSubmitting) => {
        setLoading(true);

        try {
            await addIAS(iasData);
            fetchData();
            setSubmitting(false);
        } catch (error) {
            setError(error);
        } finally {
            setShowDialog(false);
            setLoading(false);
        }
    };

    const changeIas = async (iasData, id, setSubmitting) => {
        setLoading(true);

        try {
            const {data: updatedIas} = await updateIAS(
                id,
                iasData
            );

            const oldIas = ias.find(
                (ias) => ias.id === updatedIas.id
            );

            const oldIasIndex = ias.indexOf(oldIas);

            ias[oldIasIndex] = updatedIas;

            setIas([...ias]);

            setSubmitting(false);
        } catch (error) {
            setError(error);
        } finally {
            setShowDialog(false);
            setLoading(false);
        }
    };

    if (loading) {
        return <Loading/>;
    }

    if (error && isNotBusinessError(error)) {
        return handleError(error);
    }

    return (
        <SubTemplate hasBackButton titleId={'ias.manage'}>
            <div className={'container'}>
                <Row>
                    <Col md="2">
                        <div className='mb-4'>
                            <button
                                className='btn btn-outline-primary mb-2'
                                onClick={() => handleAddClick()}>
                                <TiPlus className='mb-1 me-2' aria-hidden='true'/>
                                <FormattedMessage id='ias.add'/>
                            </button>
                        </div>
                    </Col>
                    <Col md="8">
                        <AlertError error={error} className='mt-2'/>
                        <Row className='d-flex align-items-center mt-3'>
                            <Col lg='12'>
                                {Arrays.isNotEmpty(ias) && (
                                    <>
                                        <div>
                                            {totalResults} {totalResults === 1 ?
                                            <FormattedMessage id='all.results.lowCase.singular'/> :
                                            <FormattedMessage id='all.results.lowCase.plural'/>}
                                        </div>
                                    </>
                                )}
                            </Col>
                        </Row>
                        {Arrays.isNotEmpty(ias) ? (
                            <ul className={'results'}>
                                {ias.map((ias, index) => (
                                    <li key={ias.name}>
                                        <div className='row py-3'>
                                            <div className='col-md-8'>
                                                <p className='font-weight-bold mb-0'>{ias.name} · {ias.value.toFixed(2)}€</p>
                                                <p className='mb-0'>{ias.startDate} a {ias.endDate}</p>
                                            </div>

                                            <div className='col-md-4 d-flex justify-content-end'>
                                                {/*<button*/}
                                                {/*    onClick={() => handleDelete(ias.value)}*/}
                                                {/*    className='btn btn-link'*/}
                                                {/*>*/}
                                                {/*    <FaTrashAlt/>*/}
                                                {/*</button>*/}

                                                <button
                                                    className='btn btn-link'
                                                    onClick={() => handleEditClick(ias)}
                                                >
                                                    <FaEdit/>
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <EmptyResults/>
                        )}
                    </Col>
                    {showDialog && update && (
                        <IASDialog
                            show={showDialog}
                            handleClose={handleDialogClose}
                            handleSubmit={handleSubmit}
                            ias={selectedIAS}
                            isUpdate={true}
                        />
                    )}

                    {showDialog && !update && (
                        <IASDialog
                            show={showDialog}
                            handleClose={handleDialogClose}
                            handleSubmit={handleSubmit}
                            isUpdate={false}
                        />
                    )}
                </Row>
            </div>
        </SubTemplate>
    );
}
