import {apiAxios as authAxios} from '../axios.config';

export const getIAS = () => {
    return authAxios.get('api/ias');
};

export const updateIAS = (id, ias) => {
    return authAxios.put(`/api/ias/${id}`, ias);
};

export const getIASByExternalId = (id) => {
    return authAxios.get(`/api/ias/${id}`);
};

export const addIAS = (ias) => {
    return authAxios.post('/api/ias', ias);
};
