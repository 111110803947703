import format from 'date-fns/format';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { ConfigObject } from '../../config';
import { DateTimeInputField } from '../bootstrap/DateTimeInputField';

const INITIAL_FORM_DATA = {
  description: '',
  holidayDate: format(new Date(), ConfigObject.get().DATE_PATTERN),
};

export function HolidaysDialog({
  holiday,
  show,
  handleClose,
  handleSubmit,
  isUpdate,
}) {
  const schema = yup.object().shape({
    description: yup
      .string()
      .required(<FormattedMessage id='errors.fieldRequiredText' />),
    holidayDate: yup
      .date().transform(v => (v instanceof Date && !isNaN(v) ? v : undefined))
      .required(<FormattedMessage id='errors.fieldRequired.text' />),
  });

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id={`holidays.${isUpdate ? 'edit' : 'add'}`} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={holiday ? holiday : INITIAL_FORM_DATA}
          validationSchema={schema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            isUpdate
              ? handleSubmit(
                values,
                values.externalId,
                setSubmitting,
                isUpdate,
                setErrors
              )
              : handleSubmit(values, setSubmitting, setErrors);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Form.Group controlId='code'>
                <Form.Label>
                  <FormattedMessage id='all.description' />
                </Form.Label>
                <Form.Control
                  type='text'
                  name='description'
                  value={values.description}
                  onChange={handleChange}
                  isInvalid={touched.description && errors.description}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
              <DateTimeInputField
                  name='holidayDate'
                  value={values.holidayDate}
                  errorMessage={errors.holidayDate}
                  isInvalid={touched.holidayDate && errors.holidayDate}
                  setFieldValue={setFieldValue}
                  labelId='all.dateDescription'
                  isDateWithNoTime={true}
              ></DateTimeInputField>
              <div className="form-actions">
                <Button variant='primary' type='submit'>
                  <FormattedMessage id='all.submitButtonText' />
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
