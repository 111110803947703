import {Col, Form, Row} from 'react-bootstrap';
import {FormattedMessage, useIntl} from 'react-intl';
import {maxFixed} from '../../utils/CurrencyUtils';
import {TextAreaInputField} from '../bootstrap/TextAreaInputField';
import {TextInputField} from '../bootstrap/TextInputField';
import {DecimalInputField} from '../bootstrap/DecimalInputField';
import {DateTimeInputField} from '../bootstrap/DateTimeInputField';
import {SingleUploadArea} from '../../components/SingleUploadArea';
import ToolTip from '../bootstrap/ToolTip';
import {deleteDocument, uploadDocument,} from '../../rest/economicViabilityPaymentRequest';
import {NumberInputField} from '../bootstrap/NumberInputField';
import {deleteDocumentB7, uploadDocumentB7,} from '../../rest/priorSupportPaymentRequest';
import React, {useState} from "react";
import {IAS_MULTIPLIER_B5} from "../../utils/ConstantsUtils";
import {ProcessState} from "../../models/ProcessState";

export function PriorSupportPaymentRequestStep4({
                                                    formStep,
                                                    numberOfSteps,
                                                    errors,
                                                    handleChange,
                                                    setFieldValue,
                                                    setError,
                                                    formikValues,
                                                    handlePreviousStep,
                                                    readMode,
                                                    b5,
                                                    documents,
                                                    setDocuments,
                                                    isIEFP,
                                                }) {
    const intl = useIntl();
    const uploadCallback = async (formData) => {
        return b5
            ? await uploadDocument(formikValues.externalId, formData)
            : await uploadDocumentB7(formikValues.externalId, formData);
    };

    const deleteCallback = async (documentExternalId) => {
        return b5
            ? await deleteDocument(formikValues.externalId, documentExternalId)
            : await deleteDocumentB7(formikValues.externalId, documentExternalId);
    };

    function getTotalAmount() {
        return maxFixed(
            formikValues.iasValues
                .filter(mention => mention.verified)
                .reduce((acc, mention) => acc + parseFloat(mention.iasAtSubmit) * IAS_MULTIPLIER_B5, 0)
        );
    }

    function getTotalAmountOld() {
        return maxFixed(
            formikValues.paymentTechnicalAnalysis.iasHowManyTimes * formikValues.iasValues[0].iasAtSubmit * IAS_MULTIPLIER_B5
        );
    }

    const handleCheckboxChange = (mentionExternalId, index, checked) => {
        formikValues.iasValues[index].verified = checked
        setFieldValue("iasValues", [...formikValues.iasValues])
    };

    return (
        <div>
            <h4 className='text-primary mb-0'>
                <FormattedMessage id='priorSupportPaymentRequest.step5.title'/>
            </h4>
            <small className='text-secondary text-uppercase'>
                {intl.formatMessage(
                    {id: 'all.step'},
                    {step: formStep, numberOfSteps: numberOfSteps}
                )}
            </small>
            <fieldset disabled={readMode == true}>
                <Row>

                    <Col md={b5 ? '3' : '4'}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage id='priorSupportPaymentRequest.step5.eaPlafond'/>
                            </Form.Label>
                            <p> {maxFixed(formikValues.totalAmount) + ' €'}</p>
                        </Form.Group>
                    </Col>
                    <Col md={b5 ? '5' : '4'}>
                        <Form.Group>
                            <Form.Label>
                                <FormattedMessage
                                    id={
                                        b5
                                            ? 'economicViabilityPaymentRequest.step3.iasAtAnalysisDispatch'
                                            : 'priorSupportPaymentRequest.step5.iasAtTheContractDate'
                                    }
                                />
                            </Form.Label>

                            {b5 ? (formikValues.processState===ProcessState.APPROVED && formikValues.iasValues.filter((ias)=> ias.verified===true).length ===0 ?  maxFixed(formikValues.iasValues[0].iasAtSubmit) + '€' :        formikValues.iasValues.map((mention, index) => (
                                <p className="my-0" key={index}>{mention.candidatureProcessNumber} - {mention.iasAtSubmit}€</p>
                            )) ): <p>{maxFixed(formikValues.paymentTechnicalAnalysis.iasAtContractCelebration) + ' €'}
                            </p>}
                        </Form.Group>
                    </Col>
                    <Col md={b5 ? '3' : '4'}>
                        {b5 ? (
                            <Row>
                                <label className="form-label"><FormattedMessage id='economicViabilityPaymentRequest.step4.howManyCandidaturesAnalyzed'/></label>
                                <Col>
                                {
                                    (formikValues.processState===ProcessState.APPROVED && formikValues.iasValues.filter((ias)=> ias.verified===true).length ===0) ?
                                        ( formikValues.paymentTechnicalAnalysis.iasHowManyTimes):
                                    formikValues.iasValues.map((mention, index) => (
                                    <div key={index} className="d-flex gap-3">
                                        <Form.Check
                                            type='checkbox'
                                            onChange={(e) => handleCheckboxChange(mention.externalId, index, e.target.checked)}
                                            checked={formikValues.iasValues[index].verified}

                                        />
                                        <span>{mention.candidatureProcessNumber}</span>
                                    </div>



                                ))}
                        </Col>
                            </Row>
                            // <NumberInputField
                            //     maxLength={2}
                            //     labelId='economicViabilityPaymentRequest.step4.howManyCandidaturesAnalyzed'
                            //     className='w-50'
                            //     toolTip={
                            //         <ToolTip
                            //             message={
                            //                 <FormattedMessage
                            //                     id={
                            //                         b5
                            //                             ? 'economicViabilityPaymentRequest.step3.totalAmount.tooltip'
                            //                             : 'priorSupportPaymentRequest.step5.howManyIAS.placeholder'
                            //                     }
                            //                 />
                            //             }
                            //         />
                            //     }
                            //     name='paymentTechnicalAnalysis.iasHowManyTimes'
                            //     value={formikValues.paymentTechnicalAnalysis.iasHowManyTimes}
                            //     handleChange={handleChange}
                            //     isInvalid={errors.paymentTechnicalAnalysis?.iasHowManyTimes}
                            //     errorMessage={errors.paymentTechnicalAnalysis?.iasHowManyTimes}
                            // />
                        ) : (
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id='priorSupportPaymentRequest.step5.howManyIAS'/>
                                    <ToolTip
                                        message={
                                            <FormattedMessage
                                                id={
                                                    b5
                                                        ? 'economicViabilityPaymentRequest.step3.totalAmount.tooltip'
                                                        : 'priorSupportPaymentRequest.step5.howManyIAS.placeholder'
                                                }
                                            />
                                        }
                                    />
                                </Form.Label>
                                <p> {formikValues.paymentTechnicalAnalysis.iasHowManyTimes}</p>
                            </Form.Group>
                        )}
                    </Col>
                </Row>

                <Row>
                    <Col md='4'>
                        {b5 ? (

                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id='priorSupportPaymentRequest.step5.totalAmount'/>
                                </Form.Label>
                                <p> { ((formikValues.processState===ProcessState.APPROVED && formikValues.iasValues.filter((ias)=> ias.verified===true).length ===0) ? getTotalAmountOld() : getTotalAmount()) + ' €'}</p>
                            </Form.Group>
                        ) : (
                            <DecimalInputField
                                name='paymentTechnicalAnalysis.totalAmount'
                                value={formikValues.paymentTechnicalAnalysis.totalAmount}
                                handleChange={handleChange}
                                isInvalid={errors.paymentTechnicalAnalysis?.totalAmount}
                                errorMessage={errors.paymentTechnicalAnalysis?.totalAmount}
                                labelId='priorSupportPaymentRequest.step5.totalAmount'
                                suffixLabel=' €'
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col md='12'>
                        <TextAreaInputField
                            labelId='priorSupportPaymentRequest.step5.observations'
                            name='paymentTechnicalAnalysis.observations'
                            value={formikValues.paymentTechnicalAnalysis.observations}
                            handleChange={handleChange}
                            isInvalid={errors.paymentTechnicalAnalysis?.observations}
                            errorMessage={errors.paymentTechnicalAnalysis?.observations}
                            placeholder={intl.formatMessage(
                                {
                                    id: 'annualComplementaryActivitiesPlanForm.characters.placeholder',
                                },
                                {nChars: 1000}
                            )}
                            maxLength={1000}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextInputField
                            labelId='priorSupportPaymentRequest.step5.technician'
                            name='paymentTechnicalAnalysis.technicianName'
                            value={formikValues.paymentTechnicalAnalysis.technicianName}
                            handleChange={handleChange}
                            isInvalid={errors.paymentTechnicalAnalysis?.technicianName}
                            errorMessage={errors.paymentTechnicalAnalysis?.technicianName}
                        />
                    </Col>
                </Row>
                {b5 ? (
                    <>
                        <Row className='mt-5'>
                            <small className='text-primary text-uppercase'>
                                <FormattedMessage
                                    id='annualComplementaryActivitiesPlanForm.opinionAnalysisAndDecisionProposal'/>
                            </small>
                        </Row>
                        <Row className='mb-3'>
                            <Col md='6'>
                                <DateTimeInputField
                                    labelId='annualComplementaryActivitiesPlanForm.disptachDate'
                                    name='proposalDispatchDate'
                                    value={formikValues.proposalDispatchDate}
                                    setFieldValue={setFieldValue}
                                    isDateWithNoTime={true}
                                    isInvalid={errors.proposalDispatchDate}
                                    errorMessage={errors.proposalDispatchDate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInputField
                                    labelId='annualComplementaryActivitiesPlanForm.director'
                                    name='paymentTechnicalAnalysis.directorName'
                                    value={formikValues.paymentTechnicalAnalysis.directorName}
                                    handleChange={handleChange}
                                    isInvalid={errors.paymentTechnicalAnalysis?.directorName}
                                    errorMessage={errors.paymentTechnicalAnalysis?.directorName}
                                />
                            </Col>
                        </Row>

                        <Row className='mt-5'>
                            <small className='text-primary text-uppercase'>
                                <FormattedMessage id='annualComplementaryActivitiesPlanForm.decision'/>
                            </small>
                        </Row>
                        <Row>
                            <Col md='6'>
                                <DecimalInputField
                                    name='approvedAmount'
                                    value={formikValues.approvedAmount}
                                    handleChange={handleChange}
                                    isInvalid={errors.approvedAmount}
                                    errorMessage={errors.approvedAmount}
                                    labelId='annualComplementaryActivitiesPlanForm.totalApprovedAmount'
                                    suffixLabel=' €'
                                />
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            {isIEFP && (
                                <Col md='6'>
                                    <TextInputField
                                        className='w-25'
                                        name='decisionDispatchNumber'
                                        value={formikValues.decisionDispatchNumber}
                                        labelId='annualComplementaryActivitiesPlanForm.disptachInformationNumber'
                                        handleChange={handleChange}
                                        isInvalid={errors.decisionDispatchNumber}
                                        errorMessage={errors.decisionDispatchNumber}
                                    />
                                </Col>
                            )}
                            <Col md='6'>
                                <DateTimeInputField
                                    labelId='annualComplementaryActivitiesPlanForm.disptachDate'
                                    name='decisionDispatchDate'
                                    value={formikValues.decisionDispatchDate}
                                    setFieldValue={setFieldValue}
                                    isDateWithNoTime={true}
                                    isInvalid={errors.decisionDispatchDate}
                                    errorMessage={errors.decisionDispatchDate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md='12'>
                                <SingleUploadArea
                                    documents={documents}
                                    setDocuments={setDocuments}
                                    documentType={'DECISION_DISPATCH'}
                                    setError={setError}
                                    processUploadCallback={uploadCallback}
                                    processDeleteCallback={deleteCallback}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInputField
                                    labelId={'annualComplementaryActivitiesPlanForm.deputy'}
                                    name='regionalDelegateName'
                                    value={formikValues.regionalDelegateName}
                                    handleChange={handleChange}
                                    isInvalid={errors.regionalDelegateName}
                                    errorMessage={errors.regionalDelegateName}
                                />
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        <Row className='border-top mt-5'>
                            {isIEFP && (
                                <Col md='6'>
                                    <TextInputField
                                        className='w-25'
                                        name='decisionDispatchNumber'
                                        value={formikValues.decisionDispatchNumber}
                                        labelId='annualComplementaryActivitiesPlanForm.disptachInformationNumber'
                                        handleChange={handleChange}
                                        isInvalid={errors.decisionDispatchNumber}
                                        errorMessage={errors.decisionDispatchNumber}
                                    />
                                </Col>
                            )}
                            <Col md='6'>
                                <DateTimeInputField
                                    labelId='annualComplementaryActivitiesPlanForm.disptachDate'
                                    name='decisionDispatchDate'
                                    value={formikValues.decisionDispatchDate}
                                    setFieldValue={setFieldValue}
                                    isDateWithNoTime={true}
                                    isInvalid={errors.decisionDispatchDate}
                                    errorMessage={errors.decisionDispatchDate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md='12'>
                                <SingleUploadArea
                                    documents={documents}
                                    setDocuments={setDocuments}
                                    documentType={'DECISION_DISPATCH'}
                                    setError={setError}
                                    processUploadCallback={uploadCallback}
                                    processDeleteCallback={deleteCallback}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInputField
                                    labelId='priorSupportPaymentRequest.step5.director'
                                    name='paymentTechnicalAnalysis.directorName'
                                    value={formikValues.paymentTechnicalAnalysis.directorName}
                                    handleChange={handleChange}
                                    isInvalid={errors.paymentTechnicalAnalysis?.directorName}
                                    errorMessage={errors.paymentTechnicalAnalysis?.directorName}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </fieldset>
            <div className='form-actions mt-5'>
                <button
                    className='btn btn-link'
                    type='button'
                    onClick={handlePreviousStep}
                >
                    <FormattedMessage id='all.back'/>
                </button>
            </div>
        </div>
    );
}
