import {Formik} from 'formik';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import * as yup from 'yup';
import {DateTimeInputField} from '../bootstrap/DateTimeInputField';
import DecimalInputField from "../bootstrap/DecimalInputField";
import {TextInputField} from "../bootstrap/TextInputField";

const INITIAL_FORM_DATA = {
    name: '',
    startDate: '',
    endDate: '',
    value: '',
};

export function IASDialog({
                              ias,
                              show,
                              handleClose,
                              handleSubmit,
                              isUpdate,
                          }) {
    const schema = yup.object().shape({
            name: yup.string().required(<FormattedMessage id='errors.fieldRequiredText'/>),
            startDate: yup.date().required(<FormattedMessage id='errors.fieldRequiredText'/>),
            endDate: yup.date().required(<FormattedMessage id='errors.fieldRequiredText'/>).test('is-after-start',
                <FormattedMessage id={'errors.ias.endDate'}/>, (endDate, context) => {
                    const {startDate} = context.parent;
                    return !startDate || !endDate || startDate < endDate;
                }),
            value: yup.number().required(<FormattedMessage id='errors.fieldRequiredText'/>).min(0, <FormattedMessage
                id='errors.fieldRequiredText'/>),
        })
    ;

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FormattedMessage id={`ias.${isUpdate ? 'edit' : 'add'}`}/>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={ias ? ias : INITIAL_FORM_DATA}
                    validationSchema={schema}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={(values, {setSubmitting, setErrors}) => {
                        isUpdate
                            ? handleSubmit(
                                values,
                                values.id,
                                setSubmitting,
                                isUpdate,
                                setErrors
                            )
                            : handleSubmit(values, setSubmitting, setErrors);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <TextInputField
                                labelId={'all.name'}
                                name={'name'}
                                value={values.name}
                                isInvalid={touched.name && errors.name}
                                errorMessage={errors.name}
                                handleChange={handleChange}
                            />
                            <DateTimeInputField
                                name='startDate'
                                value={values.startDate}
                                errorMessage={errors.startDate}
                                isInvalid={touched.startDate && errors.startDate}
                                setFieldValue={setFieldValue}
                                labelId='ias.startDate'
                                isDateWithNoTime={true}
                            ></DateTimeInputField>

                            <DateTimeInputField
                                name='endDate'
                                value={values.endDate}
                                errorMessage={errors.endDate}
                                isInvalid={touched.endDate && errors.endDate}
                                setFieldValue={setFieldValue}
                                labelId='ias.endDate'
                                isDateWithNoTime={true}
                            ></DateTimeInputField>

                            <DecimalInputField
                                suffixLabel=' €'
                                name={'value'}
                                handleChange={handleChange}
                                labelId='all.value'
                                value={values.value}
                                isInvalid={errors.value}
                                errorMessage={errors.value}
                            />

                            <div className="form-actions">
                                <Button variant='primary' type='submit'>
                                    <FormattedMessage id='all.submitButtonText'/>
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}
