import { apiAxios as authAxios } from '../axios.config';

export const getCandidatureEconomicViability = (candidatureExternalId,queryParams) => {
  return authAxios.get(`/api/economicViability/candidature/${candidatureExternalId}`,{params: queryParams});
};

export const saveEconomicViability = (data) => {
  return authAxios.post('api/economicViability', data);
} 

export const getCandidatureProcessNumberExternalIdMappingForEconomicViabilitiesThatHaveNoPayment = (queryParams) => {
  return authAxios.get('api/economicViability/paymentNotRequested/candidature', {params: queryParams});
} 

export const getIasValueWhenEconomicViabilityWasCreated = (candidatures) => {
  return authAxios.post(`api/economicViability/candidature/ias`, candidatures);
} 

export const listEntityEconomicViabilities = (queryParams) => {
  return authAxios.get(`api/economicViability/list`, {params: queryParams});
} 
