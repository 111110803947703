import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { FormattedMessage, useIntl } from 'react-intl';
import { PromoterType } from '../../models/PromoterType';
import { CandidatureEmployeeSupportUtils, IAS } from '../../utils/CandidatureEmployeeSupportUtils';
import ToolTip from '../bootstrap/ToolTip';
import {EnumSelectField} from "../bootstrap/EnumSelectField";
import {GenderType} from "../../models/GenderType";
import {TimeType} from "../../models/TimeType";
import DecimalInputField from "../bootstrap/DecimalInputField";
import IntInputField from "../bootstrap/IntInputField";
import {currencyFormat, maxFixed} from "../../utils/CurrencyUtils";
import {Arrays} from "../../utils/Arrays";
import {yearStatus} from "../../rest/additionalActivitiesPlan";

export function CandidatureFormStep3({
  errors,
  values,
  setFieldValue,
  handleSave,
  handleGoBack,
  numberOfSteps,
  isValidate,
  isContestation,
  isCandidatureChange,
    hideNavigation,
                                       isYearChange
}) {
  const intl = useIntl();


  let financialInvestmentDetails = values.candidatureEmployeeSupportDetails.filter(
      (fid) => fid.kind === 'TEMPORARY_CANDIDATURE'
  );



  const kind =Arrays.isNotEmpty(financialInvestmentDetails) ? 'TEMPORARY_CANDIDATURE' :   isCandidatureChange ? 'CANDIDATURE_CHANGE' : isContestation ? 'IEFP' : 'PROMOTER';

  if (isValidate && !isContestation) {





    values.candidatureEmployeeSupportDetails = values.candidatureEmployeeSupportDetails.filter(
      (detail) => detail.kind === kind
    );


    values.candidatureEmployeeSupportOtherDetails = values.candidatureEmployeeSupportOtherDetails.filter(
        (detail) => detail.kind === kind
    );
  }




  if (isContestation) {
    let details = (values.candidatureEmployeeSupportDetails =
      values.candidatureEmployeeSupportDetails.filter((detail) => detail.kind === kind));


     if(details.length==0){
       details = details = (values.candidatureEmployeeSupportDetails =
           values.candidatureEmployeeSupportDetails.filter((detail) => detail.kind === 'IEFP'));
     }


    if (details.length === 0) {
      let promoterDetails = values.candidatureEmployeeSupportDetails.filter(
        (detail) => detail.kind === 'PROMOTER'
      );

      promoterDetails.forEach(pd => pd.kind = kind);

      values.candidatureEmployeeSupportDetails = promoterDetails;
    } else {
      values.candidatureEmployeeSupportDetails = details;
    }

    let otherDetails = (values.candidatureEmployeeSupportOtherDetails =
        values.candidatureEmployeeSupportOtherDetails.filter((detail) => detail.kind === kind));


    if(otherDetails.length==0){
      otherDetails = (values.candidatureEmployeeSupportOtherDetails =
          values.candidatureEmployeeSupportOtherDetails.filter((detail) => detail.kind === 'IEFP'));
    }


    if (otherDetails.length === 0) {
      let promoterDetails = values.candidatureEmployeeSupportOtherDetails.filter(
          (otherDetails) => otherDetails.kind === 'PROMOTER'
      );

      promoterDetails.forEach(pd => pd.kind = kind);

      values.candidatureEmployeeSupportOtherDetails = promoterDetails;
    } else {
      values.candidatureEmployeeSupportOtherDetails = otherDetails;
    }

  }



  function handleNextStep() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleSave(true);
  }

  async function addEmployeeSupportDetail() {
    values.candidatureEmployeeSupportDetails.push({
      externalId: '',
      promoterMetaData: '',
      timeType:'-1',
      hours:0,
      totalSubsidy: 0,
      kind: kind
    });
    setFieldValue('candidatureEmployeeSupportDetails', [
      ...values.candidatureEmployeeSupportDetails
    ]);
    values.candidatureFinancialDetails.totalJobSubsidy =
      CandidatureEmployeeSupportUtils.getTotalJobSubsidyValue(
        values.candidatureEmployeeSupportDetails
      );
    setFieldValue('candidatureFinancialDetails', {
      ...values.candidatureFinancialDetails
    });
  }

  function removeEmployeeSupportDetails(detailValue) {
    values.candidatureEmployeeSupportDetails = values.candidatureEmployeeSupportDetails.filter(
      (detail) => detail !== detailValue
    );
    setFieldValue('candidatureEmployeeSupportDetails', [
      ...values.candidatureEmployeeSupportDetails
    ]);
    values.candidatureFinancialDetails.totalJobSubsidy =
      CandidatureEmployeeSupportUtils.getTotalJobSubsidyValue(
        values.candidatureEmployeeSupportDetails
      );
    setFieldValue('candidatureFinancialDetails', {
      ...values.candidatureFinancialDetails
    });
  }

  function handleEmployeeSupportDetailChange(e, index, name) {

    values.candidatureEmployeeSupportDetails[index][name] = e.target.value;

    if(name==='timeType') {
      values.candidatureEmployeeSupportDetails[index]['hours'] = 0;
    }

    console.log(values)
    console.log(15 * parseFloat(values.ias))
    if(values.candidatureEmployeeSupportDetails[index]['promoterMetaData']==='-1'){
      values.candidatureEmployeeSupportDetails[index]['totalSubsidy'] = 0;
    }else if(values.candidatureEmployeeSupportDetails[index]['timeType']==='TOTAL'){
      values.candidatureEmployeeSupportDetails[index]['totalSubsidy'] = 15 * values.ias;
    }else {
      let hours = values.candidatureEmployeeSupportDetails[index]['hours'];
      if(hours===''){
        hours=0;
      }





      values.candidatureEmployeeSupportDetails[index]['totalSubsidy'] =  parseFloat(parseFloat(15 * values.ias)*parseInt(hours)/40);
    }


    setFieldValue('candidatureEmployeeSupportDetails', [
      ...values.candidatureEmployeeSupportDetails
    ]);
    values.candidatureFinancialDetails.totalJobSubsidy =
      CandidatureEmployeeSupportUtils.getTotalJobSubsidyValue(
        values.candidatureEmployeeSupportDetails
      );
    setFieldValue('candidatureFinancialDetails', {
      ...values.candidatureFinancialDetails
    });
  }


  function handleEmployeeSupportOtherDetailChange(e, index, name) {
        values.candidatureEmployeeSupportOtherDetails[index][name] = e.target.value;
        setFieldValue('candidatureEmployeeSupportOtherDetails', [
        ...values.candidatureEmployeeSupportOtherDetails
        ]);
  }




  function canAddEmployeeSupportDetail() {
    return (
      values.candidatureEmployeeSupportDetails.length <
        values.promotersMetaData.filter((pm) => pm.promoterType !== PromoterType.OTHER).length &&
      values.candidatureEmployeeSupportDetails.length < 5
    );
  }

  return (
    <div>
      <div>
      <h4 className='text-primary mb-0'>
        {isValidate ? (
          <FormattedMessage id='candidatureForm.workStationsIdentificationApproved' />
        ) : (
          <FormattedMessage id='candidatureForm.workStationsIdentification' />
        )}

      </h4>
      <small className='text-secondary text-uppercase'>
        {intl.formatMessage(
          { id: 'all.step' },
          { step: values.step, numberOfSteps: numberOfSteps }
        )}
      </small>
      </div>

      <h5 className='text-secondary mt-4'>
        <FormattedMessage id={`candidatureForm.workStations.ownJobs`} />
        <ToolTip message={<FormattedMessage id='candidatureForm.title.step4.toolTip' />} />
      </h5>

      {values.candidatureEmployeeSupportDetails.map((detail, index) => (
        <div key={index} className='border-bottom'>
          <p className='mt-4 mb-0 text-uppercase'>Posto de trabalho {index + 1} </p>
          <Row>
            <Col md= { detail.timeType  === TimeType.PARTIAL? '4' :'6'}>



                  <Form.Group >
                    <Form.Label>
                      <FormattedMessage id='workStations.promoter' />
                    </Form.Label>
                    <Form.Control
                        as='select'
                        name={'promoterMetaData'+index}
                        onChange={(e) => handleEmployeeSupportDetailChange(e, index, 'promoterMetaData')}
                        value={detail.promoterMetaData}
                        isInvalid={ errors.candidatureEmployeeSupportDetails?.[index]?.promoterMetaData}>
                      <option value={'-1'}>
                        <FormattedMessage id='all.chooseOne' />
                      </option>
                      {values.promotersMetaData?.filter(p=>p.promoterType===PromoterType.PRIMARY || p.promoterType===PromoterType.SECONDARY).map((pMD,index) => (
                          <option key={index} value={index}>
                            {pMD.name}
                          </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>{errors.candidatureEmployeeSupportDetails?.[index]?.promoterMetaData}</Form.Control.Feedback>
                  </Form.Group>

            </Col>
            <Col md= { detail.timeType  === TimeType.PARTIAL? '4' :'6'}>

              <EnumSelectField
                  controlId={'timeType'}
                  labelId={'workStations.timeType'}
                  preffixDescriptionId={'workStations.timeType'}
                  name={'timeType'+index}
                  handleChange={(e) => handleEmployeeSupportDetailChange(e, index, 'timeType')}
                  options={TimeType.values()}
                  value={detail.timeType}
                  nullOption={true}
                  isInvalid={
                    errors.candidatureEmployeeSupportDetails?.[index]?.timeType
                  }
                  errorMessage={
                    errors.candidatureEmployeeSupportDetails?.[index]?.timeType
                  }
              />




            </Col>

            {
              detail.timeType  === TimeType.PARTIAL && (
                  <Col md='4'>

                    <IntInputField
                        labelId={'workStations.hours'}
                        value={detail.hours}
                        name={'hours'+index}
                        handleChange={(e) => handleEmployeeSupportDetailChange(e, index, 'hours')}
                        isInvalid={
                          errors.candidatureEmployeeSupportDetails?.[index]?.hours
                        }
                        errorMessage={
                          errors.candidatureEmployeeSupportDetails?.[index]?.hours
                        }
                        maxValue={39}
                    />
                  </Col>


                    )
                    }





          </Row>

          {values.candidatureEmployeeSupportDetails.length !== 0 && (
            <div className='d-flex justify-content-between align-items-center mb-2 mt-3'>
              <p className='mb-0'>
                <span className='text-secondary'>Valor: </span> {currencyFormat(maxFixed(detail.totalSubsidy))}
              </p>

              <button
                className='btn btn-link py-0'
                type='button'
                onClick={() => removeEmployeeSupportDetails(detail)}>
                <FormattedMessage id={isValidate ? 'all.notApprove' : 'all.remove'} />
              </button>
            </div>
          )}
        </div>
      ))}

      {canAddEmployeeSupportDetail() && (
        <button
          className='mt-4 btn btn-outline-primary'
          type='button'
          onClick={() => addEmployeeSupportDetail()}>
          <FormattedMessage id='all.plusSymbol' />
          Adicionar posto de trabalho
        </button>
      )}

      <p className='mt-3'>
        <span className='text-secondary'><FormattedMessage id={'candidatureForm.workStations.ownJobsTotal'}/>: </span>
        {currencyFormat(maxFixed(CandidatureEmployeeSupportUtils.getTotalJobSubsidyValue(
          values.candidatureEmployeeSupportDetails
        )))}

      </p>


      <h5 className='text-secondary mt-4'>
        <FormattedMessage id={`candidatureForm.workStations.otherJobs`} /> <ToolTip message={<FormattedMessage id={'workstation.otherJobs.tooltip'}/>}/>
      </h5>


      {values.candidatureEmployeeSupportOtherDetails.map((detail, index) => (
          <React.Fragment key={index}>
          <Row>
            <Col md= '4'>
              <IntInputField
                  labelId={'workStations.level5to7'}
                  value={detail.level5to7}
                  name={'level5to7'+index}
                  handleChange={(e) => handleEmployeeSupportOtherDetailChange(e, index, 'level5to7')}
                  isInvalid={
                    errors.candidatureEmployeeSupportOtherDetails?.[index]?.level5to7
                  }
                  errorMessage={
                    errors.candidatureEmployeeSupportOtherDetails?.[index]?.level5to7
                  }
              />
            </Col>
            <Col md= '4'>
              <IntInputField
                  labelId={'workStations.level8'}
                  value={detail.level8}
                  name={'level8'+index}
                  handleChange={(e) => handleEmployeeSupportOtherDetailChange(e, index, 'level8')}
                  isInvalid={
                    errors.candidatureEmployeeSupportOtherDetails?.[index]?.level8
                  }
                  errorMessage={
                    errors.candidatureEmployeeSupportOtherDetails?.[index]?.level8
                  }
              />
            </Col>
            <Col md= '4'>
              <IntInputField
                  labelId={'workStations.jobsOnIEFP'}
                  value={detail.jobsOnIEFP}
                  name={'jobsOnIEFP'+index}
                  handleChange={(e) => handleEmployeeSupportOtherDetailChange(e, index, 'jobsOnIEFP')}
                  isInvalid={
                    errors.candidatureEmployeeSupportOtherDetails?.[index]?.jobsOnIEFP
                  }
                  errorMessage={
                    errors.candidatureEmployeeSupportOtherDetails?.[index]?.jobsOnIEFP
                  }
              />
            </Col>



          </Row>
            <Row>
              <Col md={'4'}>
                <IntInputField
                    labelId={'workStations.newJobs'}
                    value={detail.newJobs}
                    name={'newJobs'+index}
                    handleChange={(e) => handleEmployeeSupportOtherDetailChange(e, index, 'newJobs')}
                    isInvalid={
                      errors.candidatureEmployeeSupportOtherDetails?.[index]?.newJobs
                    }
                    errorMessage={
                      errors.candidatureEmployeeSupportOtherDetails?.[index]?.newJobs
                    }
                />
              </Col>
              {values.candidatureDetails.wasCreated ===true  &&
              <Col md={'4'}>
                <IntInputField
                    labelId={'workStations.oldJobs'}
                    value={detail.oldJobs}
                    name={'oldJobs'+index}
                    handleChange={(e) => handleEmployeeSupportOtherDetailChange(e, index, 'oldJobs')}
                    isInvalid={
                      errors.candidatureEmployeeSupportOtherDetails?.[index]?.oldJobs
                    }
                    errorMessage={
                      errors.candidatureEmployeeSupportOtherDetails?.[index]?.oldJobs
                    }
                />
              </Col>

              }


            </Row>
          </React.Fragment>



      ))}

      {(!isValidate && !hideNavigation) && (
        <div className='form-actions mt-5'>
          <button
            className='btn btn-link'
            type='button'
            onClick={() => handleGoBack(values, setFieldValue)}>
            <FormattedMessage id='all.back' />
          </button>

          <button className='btn btn-primary' type='button' onClick={() => handleNextStep()}>
            <FormattedMessage id='all.nextStep' />
          </button>
        </div>
      )}
    </div>
  );
}
