import React from 'react';
import {AddPaymentRequest} from "../../components/paymentRequest/AddPaymentRequest";
import {PaymentRequestType} from "../../components/paymentRequest/PaymentRequestType";
import {
	decideFinalPaymentRequestByReport,
	deleteFinalPaymentRequestDocument,
	getFinalPaymentRequestByReport,
	listFinalPaymentRequestDocuments,
	saveFinalPaymentRequestByReport,
	submitFinalPaymentRequestByReport,
	uploadFinalPaymentRequestDocument
} from "../../rest/paymentRequest";


export function AddFinalPaymentRequest() {
	return (
		<AddPaymentRequest
			paymentRequestType={PaymentRequestType.FINAL}
			getPaymentRequest={getFinalPaymentRequestByReport}
			listPaymentRequestDocuments={listFinalPaymentRequestDocuments}
			uploadDocument={uploadFinalPaymentRequestDocument}
			deleteDocument={deleteFinalPaymentRequestDocument}
			savePaymentRequest={saveFinalPaymentRequestByReport}
			decidePaymentRequest={decideFinalPaymentRequestByReport}
			submitPaymentRequest={submitFinalPaymentRequestByReport}
		/>
	);
}
